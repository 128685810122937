@mixin pay-method-box-content {
    .card {
        &-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            gap: .25rem;
            background: $primary-color-light;
            border: $border-secondary-gray;
            border-radius: .75rem;
            height: 8.5rem;

            &:not(.is-active):hover {
                border: 1px solid $button-hover-primary-color;
            }

            &.is-active {
                border: 1px solid $primary-color-black;
            }

            &__title {
                font: $font-base-12-l;
                height: 1rem;

                &-mobile{
                    @include media-breakpoint-down($mobile-breakpoint-banner) {
                        display:flex;
                        flex-direction: column-reverse;
                        justify-content: center;
                        align-items: flex-start;
                        flex-grow: 1;

                        .card-body__title{
                            font-weight: 400;
                        }
                    }
                }
            }

            &__icon {
                height: 4rem;
                line-height: 4rem;

                @include media-breakpoint-down($mobile-breakpoint-banner) {
                    height: 3rem;
                    width: 3rem;
                    line-height: 3rem;
                }

                &--img {
                    width: 4rem;
                    max-height: 4rem;

                    @include media-breakpoint-down($mobile-breakpoint-banner) {
                        width: 3rem;
                        max-height: 3rem;
                    }
                }

                .icon-nexto-points{
                    @include media-breakpoint-down($mobile-breakpoint-banner) {
                        width: 3rem;
                        max-height: 3rem;
                    }
                }
            }
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                flex-direction: row;
                justify-content: flex-start;
                height: 4rem;
                padding: .5rem 1.5rem;
                gap: .75rem;
            }


        }

        &-footer {
            display: flex;
            flex-direction: row;
            padding: 0;
            justify-content: center;
            align-items: flex-start;
            gap: 0;
            text-align: center;

            @include media-breakpoint-down($mobile-breakpoint-banner) {
                flex-direction: column;
            }

            &__info {
                font: $font-base-12;

                &--error {
                    font-weight: 300;
                    color: $primary-color-orange;
                }
            }
        }
    }
}

.card-payment {
    --flex-gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: .5rem;
    align-self: start;
    max-width: 9.125rem;
    min-width: 7.3125rem;
    width: calc((100% / var(--items-count)) - (((var(--items-count) - 1) / var(--items-count)) * var(--flex-gap)));
    @include media-breakpoint-down($mobile-breakpoint-banner) {
        gap: 0;
    }

    @for $i from 1 through 10 {
        &-#{$i} {
            --items-count: #{$i};
        }
    }

    @include media-breakpoint-down($mobile-breakpoint-banner) {
        max-width: 100%;
        width: 100%;
    }
}

.payment-method {
    &-header h2 {
        font-size: 1.75rem;
    }

    &-container {
        --flex-gap: 1rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: var(--flex-gap);
        @include media-breakpoint-down($mobile-breakpoint-banner) {
            --flex-gap: .5rem;
        }

        @include pay-method-box-content;

        .change-bank-label {
            width: 100%;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            font-size: 0.8125rem;
            line-height: 0.875rem;
            margin-top: 0.5rem;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .alert-box {
            &__header {
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 300;
            }
        }
    }
}

.payment-fast-transfer {
    &-header h2 {
        font-size: 1.75rem;
    }

    &-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        gap: 1rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @include pay-method-box-content
    }

    &-box {
        width: 7.3125rem;
        height: 7.25rem;
        background-color: $primary-color-light;
        border: 1px solid $primary-color-light;
        border-radius: 0.75rem;
        text-align: center;
        cursor: pointer;

        @include pay-method-box-content
    }
}

.nexto-points-box {
    &.disabled {
        opacity: .5;

        &:hover {
            border-color: transparent;
            cursor: not-allowed;
        }
    }
}
