.vouchers-payments {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.vouchers-nexto-points-outer {
    & .vouchers-having-points-header {
        font-family: $base-font-family;
        margin: 1rem auto 1.5rem auto;
        line-height: 1.5rem;
        font-size: 1rem;
    }
}

.vouchers-nexto-points-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 2rem;
    gap: 0.20rem;
    height: 6.25rem;
    background: $primary-color-light;
    border-radius: 0.688rem;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    & .logo-books {
        flex: none;
        order: 2;
        flex-grow: 0;
        z-index: 1;
    }

    & .points-message {
        font-family: $primary-font-family-bold;
        font-style: normal;
        color: $primary-color-black;
        flex: none;
        order: 1;
        flex-grow: 0;
        z-index: 2;
    }

}

.vouchers-no-points {
    margin-top: 2rem;
    flex-direction: row;
    @include media-breakpoint-down($mobile-breakpoint) {
        height: auto;
    }

    & .logo-books {
        width: 4rem;
        height: 3.75rem;
        margin-right: 0.5rem;
    }

    & .no-points-message {
        height: 1.75rem;
        font-size: 1.375rem;
        line-height: 1.75rem;
        letter-spacing: -0.013rem;
        @include media-breakpoint-down($mobile-breakpoint) {
            flex: auto;
            height: auto;
        }
    }
}

.vouchers-having-points {
    flex-direction: column;

    & .logo-books {
        width: 5.125rem;
    }

    & .count-points-message {
        height: 3.5rem;
        font-weight: 700;
        font-size: 3rem;
        line-height: 3.5rem;
        letter-spacing: -0.036rem;
        margin: 4.7rem auto -1.2rem auto;
    }
}

.vouchers-nexto-points-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 1.5rem;
    gap: 0.5rem;
}

.nexto-points-info{
    text-align: center;
    margin-top:.5rem;
    &-content{
        font-size:.75rem;
        line-height: 1rem;
        margin-bottom: 0;
        span{
            font-weight: 300;
            display: block;
        }
    }
}
