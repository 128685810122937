.carousel {
    padding: .5rem calc(#{$carousel-arrow-size-x} + .5rem);
    margin: 0 -#{$carousel-arrow-size-x};
    @include media-breakpoint-down($mobile-breakpoint) {
        margin: 0;
    }
    @include media-breakpoint-down($mobile-breakpoint-banner) {
        padding: 0;
    }

    &-item {
        min-height: $carousel-arrow-size-y;
    }


    &--centered {
        .carousel-item {
            display: flex;
            justify-content: center;
            align-self: center;
        }
    }
}

.see-more-carousel {
    font-family: $tertiary-font-family;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0;
    border: .063px solid $primary-color-black;
    border-bottom: 0;
    padding: .5rem 1rem;
    border-top-left-radius: 1.125rem;
    height: 2.25rem;
    width: 9.5rem;
    margin-top: .563rem;
    text-align: center;
    text-decoration: none;

    .a {
        text-decoration: none;
    }

    &-card {
        font-family: $base-font-family;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 0;
    }
}

.carousel-center-title {
    background: var(--background-color);
    font-family: $primary-font-family;
    font-size: 1.25rem;
    font-weight: 500;
    padding-left:1rem;
}