.login-block {
    margin: 3.125rem auto;
    text-align: center;
    color: $primary-color-black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .invalid-feedback {
        position: relative;
    }

    &-header {
        margin-bottom: 0;
        font: $font-primary-28-b;
    }

    &-submit {
        padding: 2rem 2.5rem;
        margin-top: 1rem;
        @include media-breakpoint-down($mobile-breakpoint) {
            padding: 2rem 0;
        }
    }

    input:not([type="checkbox"]):not([type="radio"]) {
        width: 23.25rem;
        max-width: 100%;
    }


    header {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }

    &-dog {
        width: 8rem;
        height: 8rem;
    }

    h2 {
        font-family: $primary-font-family-bold;
    }

    &-info {
        padding: 0.5rem 0;
        align-items: center;
        gap: 0.5rem;
        border-bottom: 1px solid $primary-color-black;

        a {
            text-decoration: none;
            color: $primary-color-black;
            font: $font-base-14-l;
            font-style: normal;
        }
    }

    &-notify {
        color: $primary-color-black;
        text-align: center;
        font: $font-base-16-l;
        font-style: normal;
    }

    &-hint {
        text-align: left;
        font-size: 0.875rem;
    }

    &-form {
        margin: 1rem auto;

        .form-row {
            padding-bottom: 4rem;
        }
    }

    &-points-info {
        display: flex;
        justify-content: center;
        padding-top: 1rem;

        .alert {
            background: $primary-color-white;
            max-width: 49.125rem;
            flex-grow: 1;

            &__icon {
                display: flex;
                align-self: center;
            }

            &-body__text {
                display: block;
            }
        }
    }

    &-actions {
        display: flex;
        padding: 2rem 2.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        align-self: stretch;
    }

    .separator {
        margin-bottom: 1rem;
    }

    &.password-block {
        max-width: 36.125rem;

        .login-block-info {
            display: block;
            border-bottom: 0;
            font-weight: 300;
            padding: 0;
            margin-bottom: 0;
        }
    }

}

label {
    margin-bottom: 0.5em;
    font-family: $base-font-family;
}

.checkbox {
    display: flex;
    padding: .5rem;

    &__input {
        position: relative;
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #000;
        border-radius: 0.3125rem;
        margin-top: 0.125rem;
        cursor: pointer;

        &::after {
            content: ' ';
            position: absolute;
        }

        &::before {
            content: ' ';
            position: absolute;
        }

        &:checked {
            border: 0.9px solid $primary-color-black;

            &::after {
                left: 0.25rem;
                top: 0.4375rem;
                height: 0.4375rem;
                border-radius: 0.1875rem;
                border: solid #fff;
                border-width: 0 0.1875rem 0.1875rem 0;
                -webkit-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &::before {
                left: 0.5625rem;
                top: 0.1875rem;
                height: 0.75rem;
                border-radius: 0.1875rem;
                border: solid #fff;
                border-width: 0 0.1875rem 0.1875rem 0;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    &__label {
        cursor: pointer;
        padding-left: 0.625rem;
        text-align: left;
    }
}

.product {
    &.product__format--badge {
        display: inline-block;
        padding: .125rem .75rem;
        background: $primary-color-white;
        border: 1px solid $secondary-color-gray-light;
        border-radius: .5rem;
        font-size: .75rem;
        margin-right: .5rem;
    }
}


.product__formats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: .75rem;

    &--list {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: 0;
        gap: .5rem;
        flex-wrap: wrap;
    }
}

.profile {
    &-action {
        a {
            color: grey;
            font-weight: 700;
            font-size: 1.125rem;

            &.small {
                font-size: 0.875rem;
            }
        }
    }
}

.static-page {
    background-color: #fff;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    a {
        color: #000;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

.section {
    @extend .container-xxl;
    @include gap(1rem);

    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include media-breakpoint-up($mobile-breakpoint) {
        padding: 2.5rem;
    }
    @include media-breakpoint-down($mobile-breakpoint) {
        padding-left: var(--bs-gutter-x, 1.25rem);
        padding-right: var(--bs-gutter-x, 1.25rem);
    }


    &--carousel {
        padding-bottom: 1.25rem;

        &-grid {
            gap: 0;
        }

        &-outside {
            padding-left: var(--bs-gutter-x, .75rem);
            padding-right: var(--bs-gutter-x, .75rem);
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                padding: 0;
            }
        }
    }

    &--description {
        padding-bottom: 4rem;
    }

    &--details {
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include media-breakpoint-down($mobile-breakpoint) {
            .details {
                margin: 0;

                &-header, &-content {
                    align-items: flex-start;
                    padding: 1rem;
                }

                &-header, &-content:not(:last-child) {
                    border-bottom: 1px solid $secondary-color-gray-dark;
                    border-right: none;
                }

            }
        }
    }

    &--details-card {
        padding: 0;
    }

    &:empty {
        display: none;
    }
}

//@DEPRECATED replace usage when possible
.section {
    &-content {
        max-width: 34.375rem;
        margin: 2.8125rem auto 1.5625rem;
        text-align: center;
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.4375rem;
    }

    &-footer {
        margin: 1.5625rem auto;
    }

    .start-browsing & {
        &-footer {
            max-width: 43.75rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .your-library & {
        &-footer {
            max-width: 56.25rem;
        }
    }

    &.register-profits-section {
        background: $primary-color-light;
        gap: calc(2rem - 0.9375rem);
        padding: 2.5rem 2.5rem 3rem;

        .account-features {
            > .row {
                padding-left: 0.25rem;
                padding-right: 0.25rem;

                > .col-sm-12, .col-lg-3 {
                    padding: 0.9375rem 0.5rem;
                }
            }

            .card--info {
                height: 13.5rem;
            }
        }

        .register-profits-section {

            &-top {
                text-align: center;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                &-title {
                    font: $font-primary-28-b;
                    letter-spacing: -.3px;
                    margin: 0;
                }

                &-text {
                    font: $font-base-16-l;
                    margin: 0;
                }
            }

            &-btn-container {
                margin: 0 auto;
            }
        }
    }

    &.products-section {
        .grid-list__header {
            margin-bottom: .5rem;
        }
    }

}

.watch-video {
    h1 {
        font-size: 2.625rem;
    }

    .section-content {
        max-width: unset;
    }
}

.min-width-400 {
    min-width: 25rem;
}

.add-content {
    .reviews {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 41.3125rem;
        margin: auto;

        .review {
            position: relative;
            margin: 0.4375rem 3.125rem;

            &--left {
                margin-left: 0;
                align-self: start;
            }

            &--right {
                margin-right: 0;
                margin-left: auto;
                align-self: end;

                @include media-breakpoint-up($mobile-breakpoint) {
                    top: -3.75rem;
                }
            }

            &-box {
                min-height: 6.875rem;
                max-width: calc(100vw - 5.625rem);
                width: 20.125rem;
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 1.4375rem;
                padding: 0.9375rem;
                border-radius: 1rem;

                &--yellow {
                    background-color: $primary-color-yellow;
                }

                &--blue {
                    background-color: $primary-color-blue;
                }

                &__arrow {
                    position: absolute;
                    width: 1rem;
                    top: 2.5rem;

                    &--right {
                        right: -1rem;
                    }

                    &--left {
                        left: -1rem;
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                }

                &__avatar {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 100%;
                    top: 0.75rem;

                    &--right {
                        right: -3.125rem;
                    }

                    &--left {
                        left: -3.125rem;

                        @include media-breakpoint-up($mobile-breakpoint) {
                            top: 3.75rem;
                        }
                    }
                }

                &__rating {
                    font-family: $secondary-font-family;
                    display: inline-block;
                    font-size: .75rem;
                    line-height: 1.125rem;
                    border: .8px solid $primary-color-black;
                    border-radius: 0.375rem;
                    padding: 0 0.3125rem;
                    white-space: nowrap;
                }

                &__header {
                    align-items: center;
                    padding-bottom: 0.3125rem;
                }
            }

            &__date {
                font-size: 0.875rem;
                font-weight: 300;
                line-height: 1.125rem;

                @include media-breakpoint-up($mobile-breakpoint) {
                    display: none;
                }
            }
        }
    }
}

.your-profile {
    &__line {
        position: absolute;
        border-top: 0.125rem dashed $primary-color-black;
        width: 92%;
        top: 1.625rem;
        left: 57%;
    }

    .number-circle {
        position: absolute;
        border: 1px solid $primary-color-black;
        border-radius: 50%;
        width: 3.375rem;
        height: 3.375rem;
        font-family: $primary-font-family-bold;
        font-size: 2.438rem;
        text-align: center;
    }

    .bottom-text {
        font-size: 1.125rem;
        font-weight: 100;
        margin: 1.875rem 0;
    }

    &__description {
        font-family: $primary-font-family-bold;
        font-size: 2rem;
        margin-top: 1.25rem;
        text-align: center;
        line-height: 2.1875rem;
        width: 90%;
        min-height: 8.75rem;
    }
}

.banner-top {
    .banner-content {
        p {
            margin-bottom: 0;
        }
    }
}

.cart {
    &__header {
        background-color: $primary-color-light;
        font-size: 2rem;
        font-family: $primary-font-family-bold;
        padding: 3rem 0 2rem 0;

        @include media-breakpoint-down($mobile-breakpoint) {

            padding: 0 0 0.9375rem 0;
        }
    }

    &__checkout {
        background-color: $primary-color-light;
        border-radius: 0.5rem;
        margin-top: 0;
        padding: 1.25rem 1.25rem 2.5rem 1.25rem;

        background-image: linear-gradient(45deg, #fff 50%, transparent 50%),
        linear-gradient(-45deg, #fff 50%, transparent 50%);
        background-position: bottom left, bottom left;
        background-size: 1.25rem 1.25rem;
        background-repeat: repeat-x;

        position: -webkit-sticky;
        position: sticky;
        top: 5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include media-breakpoint-down($mobile-breakpoint-banner) {

            &-mobile {
                background-image: none;
                box-shadow: $cart-checkout-shadow;
                border-radius: 0;
                position: fixed;
                bottom: 0;
                left: 0;
                top: unset;
                width: 100%;
                z-index: 1000;
                margin-bottom: 0;
                padding: 1.25rem;
                gap: .5rem;

                .blik_code_container {
                    order: 1;
                    max-width: 10rem;
                    margin: 0 auto;

                    .form-floating {
                        margin-top: 0;
                    }
                }

                .cart__checkout {
                    &--submitrow {
                        order: 3;
                        align-items: center;
                    }

                    &--sum {
                        order: 2;
                        font-size: 1.375rem;
                        line-height: 1.75rem;
                    }

                    &--pricesum--value {
                        font-size: 1.375rem;
                        line-height: 1.75rem;
                    }

                }
            }
        }

        hr {
            margin: 0;
        }

        &--value {
            font-size: 1.25rem;
            font-weight: 100;
            @include media-breakpoint-down($mobile-breakpoint) {
                .col-4 {
                    font-size: 1.4375rem;
                    line-height: 2rem;
                    font-weight: 400 !important;
                }
            }
        }

        &--sum {
            font-size: 1.75rem;
            font-family: $primary-font-family-bold;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0;
            align-self: stretch;

            &--label {
                flex-grow: 1;
            }
        }

        &--pricesum {
            font-weight: bold;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: end;

            &--value {
                font: $font-primary-28-b;
            }
        }

        &--submitrow {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 0;
        }

        &--submitcontainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;
        }

        &__coupon {
            &-checkbox {
                .has-voucher {
                    &__label {
                        margin-bottom: 0;
                    }

                    &:hover {
                        border-color: $primary-color-black;
                    }
                }
            }

            &-code-applied {
                font: $font-base-14;
                display: flex;
                align-items: center;
                align-self: stretch;
                gap: .75rem;

                &__code {
                    flex-grow: 1;
                    font-size: .875rem;
                    line-height: 1.25rem;
                    font-weight: 300;
                }

                &__price {
                    font-size: 1.4375rem;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        line-height: 2rem;
                        font-weight: 300;
                    }
                }
            }

            &-code {
                display: flex;
                flex-direction: row;
                padding: 1rem .5rem;
                gap: 1rem;
                align-items: center;

                .voucher-code {
                    width: 100%;

                    &:focus {
                        box-shadow: 0 0 0 .25rem rgba(123, 123, 123, 0.25);
                        border: 1px solid $secondary-color-muted;
                    }

                    &.is-invalid {
                        border: 1px solid $primary-color-red;
                        box-shadow: 0 0 0 .25rem rgba(216, 32, 23, 0.24);
                    }
                }

                .label {
                    left: .25rem;
                }

                .form-buttons {
                    flex-shrink: 0;
                }
            }
        }
    }

    &__empty {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        img {
            width: 10.3125rem;
        }

        &--title {
            font: $font-primary-28-b;
            letter-spacing: -0.3px;
            margin: 0;
        }

        &--info {
            font-size: 1.1rem;
        }
    }
}


.divider-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 0.75rem;
        line-height: 0.9375rem;
        color: $form-feedback-icon-neutral-color;
        display: block;
        text-align: center;
        width: 1.25rem;
    }

    hr {
        height: 1px;
        background: $form-feedback-icon-neutral-color;
        width: calc(50% - 0.625rem - 0.75rem);
    }
}

.search-bar {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .icon-search {
        position: absolute;
        top: calc(50% - 1.75rem / 2);
        left: 1rem
    }

    &__clear {
        position: absolute;
        top: calc(50% - 1.75rem / 2);
        right: 1rem;
        padding: .375rem;
    }

    &__input {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-left: 3.25rem !important;
        padding-right: 2.5rem !important;
        font-family: $tertiary-font-family;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $primary-color-black;

        &:focus, &:active, &:not(:placeholder-shown) {
            padding: 0 2.5rem 0 3.25rem !important;
        }

        &::placeholder {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $secondary-color-muted !important;
            font-family: $tertiary-font-family;
        }
    }
}

.price-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 0.938rem;

        .price {
            font-size: 1.75rem;
            font-family: $primary-font-family-bold;
            font-weight: 700;
            line-height: 2.25rem;
            display: flex;
            align-items: center;
            letter-spacing: -0.3px;
        }

        .price--previous {
            font-size: 1.687rem;
            font-weight: 300;
            color: $button-color-muted;
            margin-left: 1rem;
            text-decoration: line-through;
            line-height: 2.25rem;
            display: flex;
            align-items: center;
            font-family: $base-font-family;
            font-style: normal;
        }

        .price--discount {
            font-size: 1.687rem;
            font-weight: 300;
            color: $button-color-muted;
            font-family: $base-font-family;
            font-style: normal;
            line-height: 2.25rem;
            display: flex;
            align-items: center;
        }

        &-points {
            font: $font-base-16-l;
        }
    }
}

h2 {
    &.hstack {
        margin: 0;
        font-family: $primary-font-family;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 2.25rem;
        padding-left: 0;
        gap: .5rem;
    }

    .list {
        &-header__title--count {
            margin-left: 0 !important;
        }

    }
}


.search-results {

    .hstack {
        margin: 0;
        font-family: $primary-font-family;
        font-size: 1.75rem;
        line-height: 2.25rem;
        padding-left: 0;
        gap: .5rem;

        .search-results-counter {
            font-weight: 300;
            font-size: inherit;
            line-height: inherit;
            font-family: $base-font-family;
            margin: 0;
        }
    }

    &-counter {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: .75rem 0;
        font-weight: 300;

        strong {
            font-weight: 400;
        }
    }
}

.search-header {
    margin-top: 2.5rem;
}

.address-and-data {
    @include gap(2.5rem);
    padding: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    .accordion-item {
        border: 0;
        padding: 0;

        &:has(.collapse.show) {
            @extend .data-block-radius-group
        }
    }

    .accordion-button {
        background-color: unset !important;
        font-weight: 300;
    }

    .card {
        height: unset;

        &-body {
            flex-direction: row;
            justify-content: end;
        }
    }

    .address {
        &-details {
            max-width: unset;
        }
    }

    @include media-breakpoint-down($mobile-breakpoint) {

        .card {
            &--small {
                flex-direction: column;
            }

            &-header {
                margin: unset;
            }

            &-body {
                order: 2;
                justify-content: space-between;

                &__details {
                    flex-grow: 1;
                }
            }
        }

        .address {
            &-info {
                order: 1;

                &__type {
                    font-size: .75rem;
                    font-weight: 400;
                }
            }

            &-details {
                order: 2;
                text-align: left;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }

        .btn {
            &.show-form, &.hide-form {
                font-size: .875rem;
                line-height: 1.25rem;
                font-weight: 300;
            }
        }

        .data-block {
            &-actions {
                order: 3;
                margin: 0;
                flex-direction: column;
                justify-content: flex-end;
            }

            &-radius {
                gap: 1.5rem;
                justify-content: space-between;
            }

        }
    }

    .data-block-actions {
        margin: auto 0;

        .accordion-button {
            &:not(.collapsed) {
                .label-expand {
                    display: none;
                }

                .label-collapse {
                    display: inline;
                }
            }


            &.collapsed {
                .label-expand {
                    display: inline;
                }

                .label-collapse {
                    display: none;
                }
            }

            &::after {
                @extend .icon-arrow-down;
                background-image: var(--icon-image);
            }
        }
    }

}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.875rem;
    flex-direction: column;
    gap: 1.5rem;

    &-info {
        font-size: .875rem;
        line-height: 1.25rem;
        text-align: center;
        margin: 0;
        font-weight: 300;
        color: $primary-color-black;
    }
}

.search {

    &-results {
        padding-top: .5rem;
    }

    &__empty {
        text-align: center;

        img {
            width: 10.6875rem;
            margin-bottom: 1rem;
        }

        &--title {
            font-size: 1.75rem;
            line-height: 2.25rem;
            font-family: $primary-font-family-bold;
            margin-bottom: 1rem;
            word-break: break-all;
        }

        &--info {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 300;

            strong {
                font-weight: 400;
            }
        }
    }

    &-loader {
        text-align: center;
    }

    &-progress {
        height: .25rem;
        border-radius: 0;
        width: 10rem;
        background: $secondary-color-gray-light;


        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;
        }

        .progress-bar {
            background: $secondary-color-muted;
        }
    }

}

.ps {
    &:hover > &__rail-y {
        opacity: 1;
    }

    &--active-y {
        > .ps__rail-y {
            background-color: $primary-color-light;
        }
    }

    & &__rail-y {
        width: 0.25rem;
        border-radius: 0.5rem;

        &, &:hover, &.ps--clicking {
            opacity: 1;
            background-color: $primary-color-light;

            > .ps__thumb-y {
                background-color: $primary-color-orange;
                width: inherit;
                border-radius: inherit;
            }
        }
    }
}

.coupon-code__info {
    display: flex;
    align-items: center;
    font-weight: 300;
    gap: .75rem;
    justify-content: flex-start;
    @include media-breakpoint-down($mobile-breakpoint) {
        display: inline-block;
    }
}

.blockquote {
    font-size: 2.375rem;
    line-height: 2.75rem;
    color: $primary-color-black;
    font-weight: 500;
    text-align: center;
    font-family: $primary-font-family-medium;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: stretch;
    margin: 0;

    &-footer {
        margin: 0;
        color: $primary-color-orange;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: end;
        gap: .5rem;
        font-family: $base-font-family;

        &:before {
            content: "";
        }
    }

    .hr-arrow {
        border-color: $primary-color-orange;
        background: $primary-color-orange;
        max-width: 6rem;
    }
}

.description {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-self: stretch;
    padding: 0;
    gap: 2.75rem;

    &-body {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &-content {
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $primary-color-black;
        font-family: $base-font-family;
        font-weight: 300;
        text-align: left;
        padding: 0 4rem;
        @include media-breakpoint-down($mobile-breakpoint) {
            padding: 0;
        }
    }
}

.source {
    font-weight: 300;
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: center;
    color: $secondary-color-muted;
}

.details-container {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    padding: 2.5rem 0 0;
}

.about-publisher, .about-author {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-end;
    @include media-breakpoint-down($mobile-breakpoint) {
        align-items: flex-start;
    }

}

.load-more {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 2.5rem;

    &__content {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        align-items: center;
    }

    &__counter {
        font-size: .875rem;
        line-height: 1.25rem;
        text-align: center;
        margin: 0;
        font-weight: 300;
    }

    .progress {
        background: $secondary-color-gray-light;
        border-radius: .5rem;
        height: .25rem;
        border: none;
        width: 10rem;

        &-bar {
            background: $primary-color-black;
        }
    }
}

.skeleton-box {

    &-container {
        .card-header__thumbnail {
            width: 100%;
        }

        .card-body {
            border-top: none;
        }
    }

    &__content {
        max-width: 10rem;
        align-self: center;
    }

    &__text {
        height: .75rem;
    }

    &__small {
        width: 4rem;
    }

    &__medium {
        width: 6rem;
    }

    &__large {
        width: 8rem;
    }

    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: $secondary-color-gray-light;
    border-radius: .5rem;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(89.17deg, rgba(230, 230, 230, 0) 0.8%, #B1B1B1 50.09%, rgba(230, 230, 230, 0) 96.31%);
        animation: shimmer 2s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }

    &__image {

    }

}

.my-library {
    margin-top: 2.5rem;

    .card--grid-list {
        padding-top: 0;
    }

    .list__item:last-child {
        border-bottom: $border-secondary-gray-light;
    }
}


.profile {
    &-section {
        max-width: 49.125rem;
        display: flex;
        margin: 0 auto;

        .section {
            padding: 0;
            @include media-breakpoint-down($mobile-breakpoint) {
                padding-left: var(--bs-gutter-x, 1.25rem);
                padding-right: var(--bs-gutter-x, 1.25rem);
            }

            &.agreements-section {
                .alert {
                    padding: 1rem 1.5rem;
                }
            }


            &.delete-section {
                padding: 1rem 0;
                @include media-breakpoint-down($mobile-breakpoint-banner) {
                    padding: 1rem 1.5rem;
                }

            }
        }

        &.avatar-section {
            gap: 2rem;
            padding: 2.5rem 0 1.25rem;
            flex-direction: row;
            align-items: center;
        }

        &.profile-data {
            gap: 2.5rem;
            padding: 2.5rem 0;
            flex-direction: column;
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                gap: 1.5rem;
            }
        }

        .form-check {
            padding-left: 1.75rem;

            &-input {
                width: 1.25rem;
                height: 1.25rem;
                margin-left: -1.75rem;
            }
        }

        .alert-body__text {
            flex-wrap: wrap;
            gap: .75rem;
            word-break: break-word;

            @include media-breakpoint-down($mobile-breakpoint-banner) {
                div:first-child:not(.form-check) {
                    padding: .5rem 0;
                }
            }

            .alert-body__action {
                display: flex;
                justify-content: flex-end;
                flex-grow: 1;
            }
        }


    }

    &-welcome {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: calc(100% - 6.25rem - 2rem);

        &__text {
            font-size: 1.75rem;
            line-height: 2.25rem;
            font-family: $primary-font-family-bold;
            margin: 0;
            word-break: break-word;
        }
    }

    &-avatar {
        width: 6.25rem;
        height: 6.25rem;

        position: relative;

        &-img {
            aspect-ratio: 1/1;
            width: 100%;
        }

        .btn {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            justify-content: center;
        }
    }

}

.shelf {


    &__empty {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        &--image {
            width: 10.625rem;
            height: 10.625rem;
        }

        &--message {
            font: $font-primary-22-b;
            letter-spacing: -0.2px;
            color: $primary-color-black;
        }

    }

    &__section {

        &.section {
            padding-bottom: 1.25rem;
        }

        .press-release .card--grid-list .card-body {
            padding-top: calc(15rem / 16);
        }

        .card--my-products-grid {
            @extend .card--grid-list;
        }

        &--more {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1.5rem 2.5rem;
            gap: 1.5rem;

            &-button, &-message {
                display: flex;
                justify-content: center;
            }

            &-message {
                font: $font-base-14-l;
                color: $primary-color-black;
            }

            &-button {
                .btn {
                    display: inline-flex;
                }
            }
        }
    }
}

.payment-cards {
    &-info {
        font: $font-base-16-l;
        margin: 0;
    }

    &__empty {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 10.625rem;
        }

        &--title {
            font: $font-primary-28-b;
            letter-spacing: -.3px;
            margin: 0;
        }

        &--info {
            font: $font-base-16-l;
            margin: 0;
        }

        &--btn {
            margin-bottom: 3rem;
        }
    }
}

.content {
    &__desktop {
        display: block;
        @include media-breakpoint-down($mobile-breakpoint) {
            display: none;
        }
    }

    &__mobile {
        display: none;
        @include media-breakpoint-down($mobile-breakpoint) {
            display: block;
        }
    }
}


.archival-releases-section {
    @include media-breakpoint-down($mobile-breakpoint) {
        .btn-sticker {
            display: none;
        }
    }
}

#filtersMobileModal {
    .filter {
        position: relative !important;
        transform: none !important;
        inset: 0;
    }
}


.premium {
    &-products {

        &-page {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
        }

        &-list {
            gap: .5rem;
            flex-direction: row;
            justify-content: center;
        }

        &-header {
            &__guest {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }

            &__user {

                display: flex;
                padding: 2.5rem 2.5rem 1.25rem 2.5rem;
                flex-direction: column;
                align-items: center;
                gap: 2.5rem;
                align-self: stretch;

                .card {

                    &--promo {
                        justify-content: space-between;
                        flex-direction: row;
                        width: 49.125rem;

                        &.no-points {
                            background-color: #CD9A67;
                        }
                    }

                    &-header {
                        display: flex;
                        align-items: flex-start;
                        padding: 0;

                        &__thumbnail {
                            display: flex;
                            width: 4rem;
                            height: 4rem;
                            padding: 0.4375rem;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0.5rem;
                            background: $secondary-color-gray-light;

                        }
                    }

                    &-body {
                        flex-direction: column;
                        flex: 1 0 0;
                        gap: 0;

                        &__title {
                            color: $primary-color-black;
                            font: $font-primary-28-b;
                            font-weight: 700;
                            letter-spacing: -0.3px;
                        }

                        &__info {
                            color: $primary-color-black;
                            font: $font-base-16-l;
                            gap: 0.25rem;

                            strong {
                                font-weight: 400;
                            }
                        }

                    }

                    &-footer {
                        &__actions {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }

                }

                @include media-breakpoint-down($mobile-breakpoint) {
                    .card {
                        &--promo {
                            display: flex;
                            width: 20rem;
                            padding: 1.5rem 2rem;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            gap: 2rem;
                        }

                        &-body {
                            &__info {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        &-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            font: $font-base-16-l;
        }

        &-help {
            padding: 2.5rem 2.5rem 1.5rem;
        }

        &-faq {
            max-width: 48rem;
            margin: 0 auto;
            width: 100%;
        }
    }

    &-headers {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
    }

    &-header {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 700;
        color: $primary-color-black;
        font-family: $primary-font-family;
        letter-spacing: -.036rem;
    }

    &-subheader {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5rem;
        font-family: $base-font-family;
    }

    &-logo {
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .5rem;
        background: $primary-color-light;
    }
}
