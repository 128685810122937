.press-release {
    height: 100%;
    text-decoration: none;

    .card-body {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;

        &__title {
            color: $primary-color-black;
            align-self: stretch;
        }

        &__price {
            display: none;
        }

        &__type {
            font: $font-base-14;
            color: $secondary-color-muted;
            align-self: stretch;
        }

        &__mutation {
            align-self: stretch;
        }
    }

    .card--grid-list {
        .card-body {
            padding: 1rem 0 0;

            &__info {
                display: none;
            }
        }
    }

    .card--small {
        .card-body {
            &__price {
                display: flex;
            }
        }
    }

    .card--list {
        .card-body {
            align-self: center;
        }
    }
}

.shelf__section {
    .press-release {
        .card--grid-list {
            .card-body {
                &__info {
                    display: block;
                    align-self: stretch;
                    order: 6;
                }
            }
        }
    }
}