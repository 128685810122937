.filters {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 0 0 0;
    gap: 1rem;

    &__content {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: .5rem;

        .filter {
            display: none;
            flex-direction: column;
            align-items: center;
            padding: 1.5rem;
            gap: 1.5rem;
            z-index: 20;

            background: $primary-color-white;
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
            border-radius: .375rem;

            &[data-show] {
                display: flex;
            }

            &-no-results{
                font: $font-base-14;
                width: 100%;
                text-align: left;
                word-break: break-word;
                max-width: 15.9375rem;
                margin-top: 1.5rem;
            }

            &-search-loader{
                text-align: center;
                margin-top: 1.5rem;
            }

            &-list {
                width: 100%;

                &__content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    max-height: 18.5rem;
                    width: 100%;
                    overflow-y: scroll;
                    list-style-type: none;
                    padding-left: .25rem;
                    padding-top: .125rem;
                    margin: 0;

                    &--no-scroll {
                        overflow: unset;    
                    }
                }

                &__info {
                    font: $font-base-14;
                    margin-bottom: .5rem;

                    &--compact {
                        margin-bottom: 0;
                    }
                }
            }

            .form-check-label {
                font-size: 1rem;
                max-width: 14.25rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: top;
                margin-bottom: 0;
            }

            &-checkbox {
                display: flex;
                align-items: start;
                width: 100%;
            }

            &-range {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: .5rem;
                width: 18.25rem;

                &__separator {
                    width: 1.1875rem;
                    min-width: 1.1875rem;
                }

                .form-input-date{
                    padding-right: .25rem;
                    padding-left: .8rem;

                }
            }

            &-date{

                .form-floating{
                    max-width: calc((100% - 1.625rem) / 2);
                }

                .filter{
                    &-range{
                        width: 17.75rem;
                    }

                    &-range__separator{
                        min-width: .625rem;
                        width: .625rem;
                    }
                }
            }

            &-btn {
                display: flex;
                align-items: center;
                gap: .5rem;
            }

            &__slider {
                width: 100%;
                height: .875rem;
                padding-top: .5rem;
            }

            &__arrow,
            &__arrow::before {
                position: absolute;
                width: 1rem;
                height: 1rem;
                background: inherit;
            }

            &__arrow {
                visibility: hidden;
                top: -0.45rem;
            }

            &__arrow::before {
                visibility: visible;
                content: '';
                transform: rotate(45deg);
            }

            .ps__thumb-y {
                right: 0;
            }

            .form-floating > label {
                left: .4rem;
            }

            &-search .form-row {
                width: 100%;
            }
        }
    }

    &-accordion{


        .filter{
            display:flex;
            box-shadow: none;

            &__arrow{
                display: none;
            }
        }
    }
}
