.banner {
    padding: 1.875rem 0;
    @include media-breakpoint-down($mobile-breakpoint-banner) {
        padding-top: 0;
        padding-bottom: 1.125rem;
    }

    &-image {
        &-sm img {
            max-width: 3.75rem;
        }
        &-md {
            text-align: center;
            img {
                max-height: 10.9375rem;
                max-width: 20.125rem;
                @include media-breakpoint-up(md) {
                    float: right;
                }
            }
        }
    }

    &-body {
        max-width: 28.125rem;
    }

    &-header {
        @extend .header-text;
        margin-top: 0.875rem;
        margin-bottom: 0.5rem;
    }

    .dynamic-blocks__banner & {
        &-content {
            font-family: $base-font-family;
            line-height: 1.25rem;
            font-weight: 300;
        }

        &-body {
            max-width: 100%;
        }

        &-image-sm {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
    .section{
        &--carousel{
            .carousel {
                margin: 0;
            }
        }
    }
}