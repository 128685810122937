.top-nav {

    &:not(.show) {
        display: inherit;
    }

    &-line {
        border-top: 0;
        border-bottom: $border-black;

        @include media-breakpoint-down($mobile-breakpoint) {
            display: none;
        }

        &--separator {
            border-top: 0;
            border-bottom: .125rem solid $primary-color-light;
        }
    }

    &.show .navbar.display-mobile {
        position: fixed;
        overflow-y: scroll;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        padding: 0 1rem;
        flex-wrap: nowrap;
    }

    .navbar {
        font-family: $system-font-family;
        padding-top: 0.9rem;
        padding-bottom: 0;

        .nav-link {
            outline: 0;
            display: flex;
            align-items: center;

            &--login {
                font: $font-base-16-l;
                padding: .3125rem;
            }
        }

        /* wersja dla menu desktopowego */
        &.display-desktop {
            .navbar-nav {
                align-items: flex-end;
                &-left {
                    .nav-item {
                        &:first-child {
                            margin-right: .375rem;
                            padding: .5rem .25rem .6875rem;
                        }
                    }
                }
            }
            .nav-item {
                border: 1px solid transparent;
                border-bottom: none;
                padding: .75rem .25rem .3rem;
                margin-right: .125rem;

                &.active {
                    position: relative;
                    margin-bottom: -0.0625rem;
                    border: $border-black;
                    border-radius: 0 1rem 0 0;

                    &:after {
                        content: "";
                        width: 100%;
                        height: .25rem;
                        background-color: $primary-color-light;
                        position: absolute;
                        bottom: -0.25rem;
                        left: 0;
                        display: block;
                        z-index: 1;
                    }
                }
                .nav-link {
                    margin-bottom: -0.25rem;
                    &-search {
                        color: $primary-color-black;
                    }
                }

                &:hover:not(.active) {
                    & .nav-link {
                        color: $primary-color-orange;
                    }
                }

                &--icon-tab {
                    padding: 0;
                    position: relative;

                    .nav-link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 3.25rem;
                        height: 2.5rem;
                    }
                }
            }
        }
    }

    .dropdown-toggle{
        &.remove-arrow:after {
            content: none;
        }
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .nav-item {
        font-family: $primary-font-family-medium;
        font-size: 1.25rem;
        letter-spacing: -0.5px;
        line-height: 1.625rem;
        text-align: center;
        display: block;
        text-decoration: none;

        .nav-link-search {
            gap: .5rem;
        }
    }
}
/* wersja dla menu mobilnego */
.navigation-bar-mobile{
    .menu{
        background: $primary-color-light;
        border-bottom: 0.063rem solid $primary-color-black;
        .logo-mobile{
            .logo-img{
                max-width: 5.938rem;
                height: 3rem;
                margin-left: 0.188rem;
            }
        }
        .icons-menu{
            .nav-link:last-child{
                position: relative;
            }
            .badge-cart-mobile {
                font-size: .75em;
                left: auto;
                right: -0.5rem;
                position: absolute;
                top: -0.3125rem;
            }

        }
        .hamburger{
            border-radius: 0.688rem;
            border: 0.063rem solid $secondary-color-gray-light;
            padding: 0.906rem 0.594rem;
            background: transparent;
            &:not(.collapsed){
                border-color: $primary-color-black;
            }
            .close{
                width: 1.813rem;
                height: 1.188rem;
            }
        }
        .icons-menu{
            .nav-link{
                border-radius: .688rem;
                border: .063rem solid $secondary-color-gray-light;
                padding: .375rem .563rem;

                &.active{
                    border: 1px solid $primary-color-black;
                }

                &:hover{
                    border: 0.063rem solid $primary-color-orange;
                    .icon{
                        background-color: $primary-color-orange;
                    }
                }
            }
        }
    }
    .submenu-mobile{
        background: $primary-color-white;
        max-width: 20rem;
        overflow: auto;
        height: calc(100vh - 4.813rem);
        box-shadow: 0.125rem 0.125rem 0.438rem rgba(0, 0, 0, 0.130327);

        &::-webkit-scrollbar{
            display: none;
        }
        .content-user{
            border-bottom: 0.063rem solid $secondary-color-gray;
            background: $primary-color-light;
            .profile-avatar-img{
                height: 3rem;
                width: auto;
            }
        }
        .accordion{
            &-item{
                padding: 0;
                border: transparent;
                background: transparent;
            }
            &-button{
                padding: 0;
                background: transparent;
                min-height: 0;
                &:not(.collapsed){
                    background: transparent;
                }
                &.all{
                    padding: 1rem;
                    border-bottom: 0.063rem solid $secondary-color-gray;
                    background: $primary-color-light;
                    &:after{
                        display: none;
                    }
                    &.active{
                        background: $secondary-color-gray-light;
                    }
                }
                .accordion-link{
                    text-decoration: none;
                    white-space: nowrap;
                    font: $font-primary-14-m;
                }
                &.link{
                    background: $primary-color-light;
                    padding: 1rem;
                    border-bottom: 0.063rem solid $secondary-color-gray;
                    &:hover{
                        background: $secondary-color-gray-light;
                    }
                    &:after{
                        display: none;
                    }
                    .nav-link{
                        color: $primary-color-black;
                    }
                }
                &.collapsed {
                    border-bottom-left-radius: 0!important;
                    border-bottom-right-radius: 0!important;
                }
            }
            &-body{
                padding: 0;
                .nav-item{
                    padding: 1rem 0 1rem 2rem;
                    border-bottom: 0.063rem solid $secondary-color-gray;
                    .btn.btn-link{
                        font: $font-base-16-l;
                        &.active{
                            font-weight: 400;
                        }
                    }
                }
                .profile-nav-menu{
                    .div-submenu{
                        padding: 0;
                        .submenu-nav{
                            flex-direction: column;
                            gap: 0;
                            padding: 0;
                            background: $primary-color-white;
                        }
                        .nav-link{
                            align-items: flex-start;
                            align-self: flex-start;
                            padding: 1rem 0 1rem 2rem!important;
                            border-bottom: 0.063rem solid $secondary-color-gray;
                            width: 100%;
                            &:hover{
                                border-bottom: 0.063rem solid $secondary-color-gray;
                            }
                        };
                    }

                }
            }
        }
        .display-submenu-nav-mobile{
            background: $primary-color-white!important;
            .submenu-nav{
                display: flex;
                flex-direction: column;
                padding: 0;
                gap: 0;
                &__item{
                    padding: 1rem 2rem;
                    border-bottom: 0.063rem solid $secondary-color-gray;
                }
            }
        }
    }


}

@each $breakpoint, $breakpoint-value in $grid-breakpoints {
    /* version for menu mobile */
    @include media-breakpoint-down($breakpoint) {
        .top-nav {
            &.container-#{$breakpoint} {
                .display-desktop, &.show + .top-nav-line {
                    display: none !important;
                }
            }
        }
    }
    /* version for menu desktop */
    @include media-breakpoint-up($breakpoint) {
        .top-nav {
            &.container-#{$breakpoint} {
                .display-mobile {
                    display: none !important;
                }
            }
        }
    }
}

.navbar-black {
    .navbar-nav {
        .nav-link {
            color: black;
        }
    }
}

.nav {
    .active {
        color: $gray-900;
    }
    &-tabs {
        align-items: flex-end;
        padding: 1rem 2rem 0;
        gap: .5rem;
        border-color: $primary-color-black;
        @include media-breakpoint-down($mobile-breakpoint-banner) {
            padding: 1rem 1rem 0;
        }
        .nav-item {
            &.active {
                background: $primary-color-white;
                margin-bottom: -.125rem;
                .nav-link {
                    padding: .75rem 1rem;
                    margin-bottom: .25rem;
                }
            }
            .nav-link {
                padding: .5rem 1rem;
                border-color: $primary-color-black;
                border-radius: 0 1rem 0 0;
                border-bottom: 0;
                font: $font-primary-18-b;
                color: $primary-color-black;
                //margin fixes tab borders from going under line on some screens, required due to margin on .nav-item.active
                margin-bottom: .01px;
                @include media-breakpoint-down($mobile-breakpoint-banner) {
                    font: $font-primary-16-b;
                }
            }

            &:not(.active) {
                .nav-link:hover {
                    border-color: $button-hover-primary-color;
                    color: $button-hover-primary-color;
                }
            }
        }
    }
}

.submenu-nav {
    font-family: $base-font-family;
    padding: .75rem 0;
    display: flex;
    flex-direction: row;
    gap: .75rem 1.5rem;

    &__item {
        display: flex;
        flex-direction: row;
        gap: .5rem;
    }

    & .nav-item:first-of-type {
        padding-left: .3rem;
    }

    .nav-link {
        font-weight: 300;
        align-self: center;
        padding: 0;
        display: flex;
        gap: .5rem;
        align-items: center;
        color: $primary-color-black;

        &:hover:not(.active) {
            color: $primary-color-orange;
        }

        &.active {
            font-weight: 400;
        }
    }
}

.nav-buttons {
    margin-left: auto;

    & .nav-link {
        color: $primary-color-black;

        & .filter-icon {
            margin-right: 0.375rem;
        }
    }
}

.breadcrumbs {
    width: 100%;
}

.nav-link.btn:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.navbar-collapse.collapsing {
    height: unset;

    &.navbar-dropdown {
        height: 0 !important;
    }
}

.badge-cart {
    font-family: $base-font-family;
    background-color: $primary-color-orange;
    color: $primary-color-white;
    z-index: 15;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: .9375rem;
}

.badge-cart-mobile {
    background-color: $primary-color-orange;
    color: $primary-color-white;
    z-index: 15;
    position: relative;
    left: -0.8125rem;
    top: -0.8125rem;
    font-size: 0.5em;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header{
    background: $primary-color-light;
    border-bottom: 1px solid $primary-color-black;
    position: fixed;
    top:0;
    width: 100%;
    z-index: 1000;

    .container{
        padding: 0 2.5rem;
    }

    .navbar{
        align-items: flex-end;
        gap: .5rem;
        padding-bottom: 0;
        padding-top: 1.375rem;
        height: 4rem;
        justify-content: flex-start;
        transition: width 2s linear 1s;


        &-nav{
            gap: .5rem;
            flex-direction: row;
        }

        &__main-menu{
            flex-grow: 1;
            align-items: flex-end;
        }

        &__logo{
            .logo-img{
                width: 5.9375rem;
                max-height: 1.68rem;
            }
        }


        &__user-menu{

            .nav-item{
                padding: .25rem .75rem;
                height: 2.4375rem;

                .nav-link{
                    line-height: 0;
                }
            }
        }

        .dropdown{
            &-menu{
                right: -100%;
                left: unset;
                top: calc(100% + 1rem);
                position: absolute;
            }
        }


    }

    .nav{
        &-item{
            padding: .5rem .75rem .25rem;
            border-radius: 0 1rem 0 0;
            position: relative;
            height: 2.6875rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover:not(.active){
                .nav-link{
                    color: $primary-color-orange;
                }
            }


            &.navbar__logo{
                padding: .5rem .75rem .25rem;
                height: 2.625rem;
            }

            &.active{
                border-width: 1px 1px 0 1px;
                border-style: solid;
                border-color: $primary-color-black;
                &:after{
                    content: " ";
                    border-bottom: 0.1875rem solid $primary-color-light;
                    position:absolute;
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                }
            }

            &.cart-count-container{
                padding-left: 0;
                margin-left: -.625rem;
                padding-right: .625rem;
            }
        }

        &-link{
            padding: 0;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
            text-align: center;
            font-family: $tertiary-font-family;
            color: $primary-color-black;
            display: flex;
            justify-content: center;
            gap: .5rem;
            align-items: flex-end;

            &.dropdown-toggle{
                &::after{
                    content: none;
                }
            }
        }
    }

    &.compact-header{
        .navbar{
            height: 3rem;
            padding-top: 0;

            &__logo{

                .icon-logo{
                    width: 5.3125rem;
                    height: 1.5rem;
                }

                .logo-img{
                    width: 5.3125rem;
                    max-height: 1.5rem;
                }
            }

            &__search-bar{
                .nav-link{
                    font-size: 1.125rem;
                }
            }
        }

        .nav{
            &-item{
                padding: .5rem .5rem .25rem;
                height: 2.4375rem;
                &.navbar__logo{
                    height: 2.5rem;
                }
            }
        }
    }
}

.submenu{
    margin-top: 4.0625rem;
    @include media-breakpoint-down(lg) {
        margin-top: 0;
        padding: 0;
    }

    .container-lg{
        padding: 0 2.5rem;
    }
}

.dropdown{
    &-menu{
        position: absolute;
        padding: 1rem .5rem;
        border-radius: .375rem;
        min-width: 75%;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
        margin: 0;
        border:none;
        width: 9.3125rem;

        &::before{

            position: absolute;
            top: -.625rem;
            left: calc(50% - .675rem);
            content: " ";
            width: 0;
            height: 0;
            border-left: .675rem solid transparent;
            border-right: .675rem solid transparent;

            border-bottom: .675rem solid $primary-color-white;
        }
    }

    &-item{
        padding: .5rem 1rem!important;
        border-radius: .375rem;
        font-size: .875rem;
        line-height: 1.25rem;
        color: $primary-color-black;

        &.active,&:active,&:hover{
            background: $secondary-color-gray-light;
            color: $primary-color-black;
        }
    }
}
