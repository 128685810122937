$icon-filter-disabled-selector: ':not(.btn--prop):not(:disabled):not([aria-disabled])' !default;
$icon-background-selector: '.icon--bg';

.icon {
    display: inline-block;
    background-color: $primary-color-black;

    --icon-size-x: #{$icon-base-size};
    --icon-size-y: #{$icon-base-size};
    width: var(--icon-size-x);
    height: var(--icon-size-y);

    mask-image: var(--icon-image);
    -webkit-mask-image: var(--icon-image);
    mask-size: cover;
    -webkit-mask-size: cover;

    &#{$icon-background-selector} {
        mask: none;
        -webkit-mask: none;

        background-image: var(--icon-image);
        background-color: transparent;
        background-size: cover;
    }

    @for $i from 1 through 12 {
        &-#{$i} {
            //Increase size by 20% for each $i
            --icon-size-x: calc(#{$icon-base-size} * (1 + #{$i} * .2));
            --icon-size-y: calc(#{$icon-base-size} * (1 + #{$i} * .2));
        }
    }

    @for $i from 1 through 4 {
        &-sm-#{$i} {
            //Decrease size by 20% for each $i
            --icon-size-x: calc(#{$icon-base-size} * (1 - #{$i} * .2));
            --icon-size-y: calc(#{$icon-base-size} * (1 - #{$i} * .2));
        }
    }

    .icon-container & {
        transition: inherit;
    }

    &:not(&--no-hover)#{$icon-filter-disabled-selector} {
        &:hover, .icon-container:hover#{$icon-filter-disabled-selector} &,
        .icon-container:focus#{$icon-filter-disabled-selector} & {
            background-color: $button-hover-primary-color;

            &.icon--white {
                background-color: $primary-color-black;
            }

            &#{$icon-background-selector} {
                background-image: var(--icon-hover-image, var(--icon-image));
                background-color: transparent;
            }
        }

        .nav-item.active &:hover,
        .nav-item.active.icon-container:hover#{$icon-filter-disabled-selector} &,
        .nav-item.active.icon-container:focus#{$icon-filter-disabled-selector} & {
            background-color: $primary-color-black;

            &#{$icon-background-selector} {
                background-image: var(--icon-image);
                background-color: transparent;
            }
        }
    }

    &--white {
        background-color: $primary-color-white;
    }

    &-plus {
        --icon-image: url('../../images/icons/plus.svg');
    }

    &-bookmark {
        --icon-image: url('../../images/icons/bookmark.svg');

        &-o {
            --icon-image: url('../../images/icons/bookmark-o.svg');
        }
    }

    &-author {
        --icon-image: url('../../images/icons/author.svg');
    }
    &-publisher {
        --icon-image: url('../../images/icons/publisher.svg');
    }

    &-basket {
        --icon-image: url('../../images/icons/basket.svg');

        &-quick {
            --icon-image: url('../../images/icons/basket-quick.svg');
        }
    }

    &-favorite {
        --icon-image: url('../../images/icons/favorite.svg');
    }

    &-profile {
        --icon-image: url('../../images/icons/profile.svg');
    }

    &-bank-transfer {
        --icon-image: url('../../images/icons/bank-transfer.svg');
    }

    &-points {
        --icon-image: url('../../images/icons/points.svg');
    }

    &-card {
        --icon-image: url('../../images/icons/card.svg');
    }

    &-nexto-points {
        --icon-image: url('../../images/icons/nexto-points.svg');
        background-color: $primary-color-light;
    }

    &-pen {
        --icon-image: url('../../images/icons/pen.svg');
    }

    &-heart {
        --icon-image: url('../../images/icons/heart.svg');

        &-o {
            --icon-image: url('../../images/icons/heart-o.svg');
        }
    }

    &-download {
        --icon-image: url('../../images/icons/download.svg');
    }

    &-arrow-down {
        --icon-image: url('../../images/icons/arrow-down.svg');
    }

    &-arrow-up {
        --icon-image: url('../../images/icons/arrow-up.svg');
    }

    &-arrow-left {
        --icon-image: url('../../images/icons/arrow-left.svg');
    }

    &-arrow-right {
        --icon-image: url('../../images/icons/arrow-right.svg');
    }

    &-slider-arrow {
        --icon-size-y: calc(2.3 * var(--icon-size-x));

        &--right {
            --icon-image: url('../../images/icons/slider-arrow-right.svg');
        }

        &--left {
            --icon-image: url('../../images/icons/slider-arrow-left.svg');
        }
    }

    &-times {
        --icon-image: url('../../images/icons/times.svg');
    }

    &-times-bold {
        --icon-image: url('../../images/icons/times-bold.svg');
    }

    &-subscription {
        --icon-image: url("../../images/icons/group-copy-8.svg");
    }

    &-info {
        --icon-image: url('../../images/icons/info.svg');
    }

    &-email {
        --icon-image: url('../../images/icons/e-mail.svg');
    }

    &-envelope {
        --icon-image: url('../../images/icons/envelope.svg');
    }

    &-key {
        --icon-image: url('../../images/icons/key.svg');
    }

    &-search {
        --icon-image: url('../../images/icons/search.svg');
    }

    &-book-read {
        --icon-image: url('../../images/icons/book-read.svg');
    }

    &-reader {
        --icon-image: url('../../images/icons/reader.svg');
    }

    &-eye {
        --icon-image: url('../../images/icons/eye.svg');
    }

    &-danger {
        --icon-image: url('../../images/icons/danger.svg');
    }

    &-warning {
        --icon-image: url('../../images/icons/warning.svg');
    }

    &-success {
        --icon-image: url('../../images/icons/success.svg');
    }

    &-login {
        --icon-image: url('../../images/icons/login.svg');
    }

    &-newspaper {
        --icon-image: url('../../images/icons/newspaper.svg');
    }

    &-star {
        --icon-image: url('../../images/icons/star.svg');

        &-filled {
            --icon-image: url('../../images/icons/star-filled.svg');
        }
    }

    &-sort {
        --icon-image: url('../../images/icons/sort.svg');
    }

    &-circle {
        width: 4.875rem;
        height: 4.875rem;
        border-radius: 50%;
        background-color: $primary-color-light;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 2.6875rem;

        &--avatar {
            background-image: url('../../images/icons/profile-add.svg');
            background-size: 50%;
        }

        &--pen {
            background-image: url('../../images/icons/pen-line.svg');
            background-size: 70%;
        }

        &--payment {
            background-image: url('../../images/icons/payment.svg');
            background-size: 50%;
        }
    }

    &-logo {
        --icon-image: url('../../images/logos/ekiosk-logo.svg');
        width: 5.9375rem;
        height: 1.68rem;
    }

    &-question-mark{
        --icon-image: url('../../images/icons/question_mark.svg');
        &:hover{
            --icon-image: url('../../images/icons/question_mark_active.svg');
        }
    }
}
