.editmode_container {
    border: .125rem solid #ffb59b;
}

.pimcore_editable_input, .pimcore_editable_wysiwyg, .pimcore_editable_textarea,
.pimcore_editable_textarea:focus .pimcore_wysiwyg:focus {
    overflow-y: visible;
    outline: #b4b4b4 dashed .063rem !important;
    outline-offset: .25rem;
}

.pimcore_area_buttons {

    &.top {
        top: -3rem;

        .pimcore_block_label {
            color: #3c3f41 !important;
            font-size: 1rem !important;
            text-transform: uppercase;
        }
    }

    .pimcore_area_buttons_inner {
        height: auto;
        flex-wrap: wrap;
        background: transparent;
    }
}