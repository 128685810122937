@font-face {
    font-family: SaolStandard-Medium;
    src: url(../../fonts/Saol/SaolStandard-Medium.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: SaolStandard-Bold;
    src: url(../../fonts/Saol/SaolStandard-Bold.ttf) format("truetype");
    font-display: swap;
}

@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);