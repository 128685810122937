.accordion {
    &-body {
        margin-top: .063rem;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }

    &-button {
        font-family: $base-font-family;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 0;
        text-align: left;
        padding: 1rem 1.5rem;
        min-height: 3.75rem;

        p {
            margin-bottom: 0;
        }

        &.collapsed {
            box-shadow: 0 0 0 rgba(236, 93, 43, 0);
            border-bottom-left-radius: 1.5rem !important;
            border-bottom-right-radius: 1.5rem !important;
        }

        &:focus {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

    }

    &-item {
        padding: .125rem;

        p {
            margin-bottom: 0;
        }
    }

    &.accordion--compact{

        .accordion{
            &-item{
                background: $primary-color-white;
                border-radius: 0;
                padding:0;
            }

            &-body{
                padding: 0;
            }


            &-button{

                font-size: 1.25rem;
                font-weight: 500;
                min-height: auto;
                font-family: $primary-font-family;

                &:not(.collapsed){
                    background: $secondary-color-gray-light!important;
                }
                &::after{
                    width: 1.5rem;
                    height: 1.5rem;
                }


                &.collapsed{
                    background: $primary-color-light!important;
                    border-bottom-left-radius: 0!important;
                    border-bottom-right-radius: 0!important;
                }
            }
        }
    }
}

.accordion-item:first-of-type .accordion-button:not(.collapsed),
.accordion-item:last-of-type .accordion-button:not(.collapsed),
.accordion-button:not(.collapsed) {
    background-color: $primary-color-light;
    color: $primary-color-muted;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .125);
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.buttons-tabs-briks {
    .nav .active {
        color: $primary-color-black;
        font-weight: 600;
    }
}