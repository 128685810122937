html {
    font-size: 1rem;
    height: 100%;
}

body {
    font-family: $base-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --background-color: #{$primary-color-white};
    background-color: var(--background-color);
    height: inherit;
    color: $primary-color-black;
}

a {
    color: inherit;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

h1, h2, h3, h4 {
    font-family: $first-font-family;
}
h3{
    font-weight: 700;
}

hr {
    opacity: unset;
}

.app {
    flex: 1 0 auto;
}

.bg {
    &-light {
        --background-color: #{$primary-color-light};
        background-color: var(--background-color);
    }

    &-white {
        --background-color: #{$primary-color-white};
        background-color: var(--background-color);
    }
}

.border {
    &-color {
        &-nexto {
            border-color: $primary-color-light !important;
        }

        &-black {
            border-color: $primary-color-black !important;
        }
    }

    &-secondary-gray-light {
        border: $border-gray;
    }

    &-secondary-gray-light {
        border: $border-gray;
    }

    &-top {
        &-1 {
            border-top: .063rem solid;
        }

    }
}

.rounded {
    &-4 {
        border-radius: 1.25rem !important;
    }

    &-4_3 {
        border-radius: 1.375rem !important;
    }

    &-5 {
        border-radius: 1.5rem !important;
    }

    &-6 {
        border-radius: 1.75rem !important;
    }

    &-7 {
        border-radius: 2rem !important;
    }
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.color {
    &-orange {
        color: $primary-color-orange !important;
        fill: $primary-color-orange !important;
    }

    &-black {
        color: $primary-color-black !important;
        fill: $primary-color-black !important;
    }
}

.header-text {
    color: #FF561B;
    font-family: "SaolStandard-Bold", sans-serif;
    font-size: 1.75rem;
    font-weight: bold;
    letter-spacing: -0.32px;
    line-height: 2.375rem;
}

.first-font-family {
    font-family: $first-font-family;
}

.base-font-family {
    font-family: $base-font-family;
}

.tertiary-font-family {
    font-family: $tertiary-font-family;
}

.covers {
    position: relative;
    min-height: 18.75rem;

    picture {
        position: absolute;

        &:nth-child(1) {
            top: 5rem;
        }

        &:nth-child(2) {
            left: 2.5rem;
            top: 2.5rem;
        }

        &:nth-child(3) {
            left: 5rem;
        }
    }

    @include media-breakpoint-down($mobile-breakpoint) {
        width: 15rem;
        margin: 0 auto;
    }
}

.books-press-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 2.5rem;

    .books-press-block {
        border-radius: 0.5rem;
        background-color: #f6f5f3;
        margin-left: 4.6875rem;
        position: relative;
        padding: .625rem 1.25rem;

        .counter {
            display: inline-block;
            border: 1px solid $primary-color-black;
            border-radius: .375rem;
            padding: 0 .313rem;
            margin-right: 1.5rem;
            margin-left: 0.313rem;
            font-size: .75rem;

            @include media-breakpoint-down($mobile-breakpoint) {
                margin-right: 0.625rem;
            }
        }

        &--margin {
            margin-right: 1.5rem;
        }

        @include media-breakpoint-down($mobile-breakpoint) {
            margin: 0px auto;
            margin-bottom: 6.875rem;
        }
    }

    .arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        transform: rotate(90deg);
        left: -1.625rem;

        .line {
            border-right: 0.125rem dashed $primary-color-black;
            display: inline-block;
            height: 6.813rem;
        }

        .arrowhead {
            position: absolute;
            top: -1px;
            bottom: 0;
            height: 0.4375rem;
            border-right: 0.125rem solid $primary-color-black;
            display: inline-block;

            &--right {
                left: 0.125rem;
                transform: rotate(-45deg);
            }

            &--left {
                right: 0.125rem;
                transform: rotate(45deg);
            }
        }

        @include media-breakpoint-down($mobile-breakpoint) {
            top: 3.125rem;
            transform: rotate(00deg);
            left: 10.1875rem;
        }
    }
}

.z-index-10 {
    z-index: 10 !important;
}

.z-index-20 {
    z-index: 20 !important;
}

.full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
}

.width-64 {
    width: 4rem !important;
}

.text-truncate-line {
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;

    @for $i from 1 through 12 {
        &-#{$i} {
            line-clamp: #{$i};
            -webkit-line-clamp: #{$i};
        }
    }


    p, h1, h2, h3, h4, h5, h6, div {
        display: inline;

        &:after {
            content: element("<br>");
        }
    }
}

.badge {
    font: $font-base-12-l;
    color: $primary-color-black;
    border: none;
    display: flex;
    gap: .25rem;
    align-items: center;

    &--light {
        border-radius: .5rem;
        padding: .25rem .75rem;
        border-color: $secondary-color-gray-light;
        background: $primary-color-light;
    }

    &--outline {
        border: $border-black;
        border-radius: .375rem;
        padding: .125rem .5rem;
    }

    &.rounded-circle {
        padding: 0;
    }
}

.highlighted-text-link {
    color: #007bff;
    text-decoration: none;

    &:hover {
        color: #007bff;
        text-decoration: underline;
    }
}

.content-margin-top {
    margin-top: $content-margin-top;
}

.hr-arrow {
    width: 100%;
    height: .5625rem;
    border: 1px solid $primary-color-black;
    border-right: none;
    border-bottom: 1px solid var(--background-color, #{$primary-color-white});
    position: relative;
    background: $primary-color-black;

    &:before, &:after {
        content: "";
        position: absolute;
        top: 0;
        height: .5rem;
        background: var(--background-color, #{$primary-color-white});
    }

    &:before {
        left: 0;
        width: .5625rem;
        border-top-left-radius: 100%;
        border: 1px solid var(--background-color, #{$primary-color-white});
        border-right: none;
        border-bottom: none;
    }

    &:after {
        left: 0.4375rem;
        width: calc(100% - 0.375rem);
    }
}

.header-counter {
    font-family: $base-font-family;
    font-weight: 300;
    display: inline-block;
    margin-left: 0.3125rem;
}

.align-center {
    display: flex;
    align-items: center;
}

.align-center-end {
    display: flex;
    align-items: center;
    justify-content: end;
    -webkit-align-items: center;
    -webkit-justify-content: flex-end;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix}-top {
            border-top: $border-width solid $border-color;
        }
        .border#{$infix}-end {
            border-right: $border-width solid $border-color;
        }
        .border#{$infix}-bottom {
            border-bottom: $border-width solid $border-color;
        }
        .border#{$infix}-start {
            border-left: $border-width solid $border-color;
        }

        .border#{$infix}-top-0 {
            border-top: 0;
        }
        .border#{$infix}-end-0 {
            border-right: 0;
        }
        .border#{$infix}-bottom-0 {
            border-bottom: 0;
        }
        .border#{$infix}-start-0 {
            border-left: 0;
        }

        .border#{$infix}-x {
            border-left: $border-width solid $border-color;
            border-right: $border-width solid $border-color;
        }

        .border#{$infix}-y {
            border-top: $border-width solid $border-color;
            border-bottom: $border-width solid $border-color;
        }

        .fw#{$infix}-bold {
            font-weight: 700 !important;
        }

        .fw#{$infix}-normal {
            font-weight: 400 !important;
        }
    }
}


.separator {
    position: relative;
    padding: 0;
    border: $border-black;


    &--line {
        background-image: linear-gradient($secondary-color-gray-dark, $secondary-color-gray-dark);
        background-size: 100% 0.063rem;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-align: center;

        &-title {
            margin: auto;
            background: var(--background-color);
        }


    }

    &--vertical {
        border-right: 0;
        width: 0;
    }

    &--horizontal {
        border-bottom: 0;
        height: 0;
    }

    &--gray {
        border-color: $secondary-color-gray;
    }

    &--gray-l {
        border-color: $secondary-color-gray-light;
    }

    &--grey-dark {
        border-color: $secondary-color-gray-dark;
    }

    &--dot {
        width: 1.5rem;
        height: .5rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: var(--background-color);
        z-index: 2;

        &:after {
            content: " ";
            border-radius: 100%;
            width: .5rem;
            height: .5rem;
            line-height: .5rem;
            background-color: $secondary-color-gray-dark;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 3
        }
    }
}

.price {
    font: $font-primary-28-b;
    color: $primary-color-black;

    &--discount, &--previous {
        font: $font-base-27-l;
        color: $secondary-color-muted;
    }

    &--discount {
        display: none;
    }

    &--previous {
        text-decoration-line: line-through;
    }

    &--lowest {
        font: $font-base-12-l;
        display: flex;
    }
}

.nexto__points--value {
    font: $font-base-14-l;
    color: $primary-color-black;
}

.half-opacity {
    opacity: .5;
}

.mw {
    &-fit-content {
        max-width: fit-content;
    }

    &-372 {
        max-width: 23.25rem;
    }

    &-578 {
        max-width: 36.125rem;
    }
}

.box-shadow-unset {
    box-shadow: unset !important;
}
