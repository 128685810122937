.cart-progressbar {
    font: $font-base-20-l;
    position: relative;
    margin-top: 0.875rem;
    margin-bottom: 0.9375rem;

    &-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        &:before {
            position: absolute;
            content: ' ';
            top: 1.4375rem;
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed $primary-color-black;
        }
    }

    &-step {
        &.disabled {
            .cart-progressbar-step-description {
                color: $primary-color-disabled;
            }

            .cart-progressbar-step-number {
                border-color: $primary-color-disabled;
                color: $primary-color-disabled;
            }
        }

        display: flex;
        gap: 1rem;
        z-index: 1;
        background-color: $primary-color-light;
        padding: 0 1rem;
        text-decoration: none;

        &-number {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font: $font-primary-36-b;
            width: 2.75rem;
            height: 2.75rem;
            border: 1px solid $primary-color-black;
            border-radius: 50%;
            background-color: $primary-color-light;
            position: relative;
            text-align: center;
            padding: 0.25rem 0;

            > div {
                width: 2.75rem;
                height: 2.75rem;
                border-radius: 50%;
                position: relative;
                padding: .125rem .72rem .375rem;

                > span {
                    font-family: "SaolStandard", serif;
                    @media not all and (min-resolution: .001dpcm) {
                        @supports (-webkit-appearance:none) {
                            line-height: 2.75rem;
                        }
                    }
                }
            }

            &.active {
                margin-left: .375rem;

                > div {
                    left: -.375rem;
                    background-color: $primary-color-black;
                    color: $primary-color-white;
                }
            }
        }

        &-description {
            margin: 0;
            position: relative;
            align-self: center;
        }

        &:first-child {
            padding-left: 0;

            .cart-progressbar-step-number {
                .active {
                    margin-left: 0.375rem;
                }
            }
        }
    }

    @media (max-width: $max-mobile-width) {
        &-step {
            flex-direction: column;
            text-align: center;
            background-color: transparent;

            &:first-child {
                text-align: left;

                .cart-progressbar-step-description {
                    padding-left: 0;
                }

                .cart-progressbar-step-number {
                    margin-left: 0;

                    &.active {
                        margin-left: 0.375rem;
                    }
                }
            }

            &:last-child {
                text-align: right;

                .cart-progressbar-step-description {
                    padding-right: 0;
                }

                .cart-progressbar-step-number {
                    margin-right: 0;
                }
            }

            &-number {
                background-color: $primary-color-white;
                margin-left: auto;
                margin-right: auto;

                &.active {
                    margin-left: auto;
                }
            }
            &-description {
                display: none;
            }

        }
    }
}

.cart {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    &-consents {
        @include gap(.5rem);
    }

    &-invoice-btn-edit {
        &:hover {
            color: $button-hover-primary-color;
        }
    }

    .product {
        &-photo {
            max-width: 4.6875rem;
        }

        &-link {
            text-decoration: none;
        }

        &-name {
            font-family: $base-font-family;
            font-size: 1.125rem;
            line-height: 1.5rem;
        }

        &-type {
            font-family: $base-font-family;
            font-size: 0.875rem;
            line-height: 1.125rem;
            font-weight: lighter;
            color: $secondary-color-muted;
        }

        &-price {
            font-family: $primary-font-family-bold;
            font-size: 1.3125rem;
            font-weight: bold;
            letter-spacing: -0.3px;
            line-height: 1.8125rem;
            text-align: right;
        }
    }

    .release-info {
        font: $font-base-14-l;
    }

    .price {
        display: block;
        text-align: right;
        color: $primary-color-black;
        font-family: $primary-font-family-medium;
        font-size: 1.375rem;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 1;

        &--previous {
            display: block;
            text-align: right;
            color: $secondary-color-muted;
            font-family: $base-font-family;
            font-size: 1.125rem;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
        }

        &--discount {
            display: none;
        }

        &--lowest {
            font: $font-base-12-l !important;
            font-weight: 300 !important;
            display: flex;

            @include media-breakpoint-down($mobile-breakpoint) {
                max-width: 8.75rem;
                width: 6.25rem;
                text-align: right;
            }
        }
    }

    &-summary {
        &-info {
            font-family: $base-font-family;
            font-size: 0.875rem;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1.125rem;
            text-align: right;
        }
    }

    &-product {
        display: flex;
        justify-content: space-between;


        &-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            flex-grow: 1;
            gap: .25rem;
        }

        .item-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            padding-left: 1.5rem;

            &:empty {
                display: none;
            }
        }

        .product {
            &__format--badge {
                background: $primary-color-light;
            }

            &-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: end;
            }

            &-formats-container {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
            }

            &-description {
                font-size: .875rem;
                line-height: 1.25rem;
                font-weight: 300;
                gap: 0;
                padding: 0;

                &:empty {
                    display: none;
                }

                p {
                    margin: 0;

                    &:empty {
                        display: none;
                    }
                }

            }
        }
    }

    &--step-1 {
        gap: 2.5rem;

        &.cart_empty {
            gap: 1.25rem;

            .price {
                font-size: 1.25rem;
                font-family: $primary-font-family;
                line-height: 1.5rem;
                font-weight: 500;
                letter-spacing: 0;
                text-align: center;

            }
        }

        .card--list {
            .price-points {
                display: none;
            }
        }
    }

    .address {
        padding: .75rem 1.5rem;

        &-info-content {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;
            gap: .25rem;
        }

        &-details {
            text-align: start;
        }

    }

    .address-block {
        .address {
            &-info {
                &__type {
                    font-size: .75rem;
                    line-height: 1rem;
                }

                &__name {
                    font-size: .875rem;
                    line-height: 1.25rem;
                }
            }

            &-details {
                font-size: .75rem;
                line-height: 1rem;
                max-width: 100%;
            }
        }
    }


    &-ordered {
        @include d-flex($gap: 32px, $dir: column);
        max-width: 49.375rem;

        .list-header__title {
            font-weight: 400;
            color: $primary-color-black;
        }

        .row:has(&) {
            @include d-flex($jc: center);
        }

        &__header {
            @include d-flex($dir: column, $ai: center, $gap: 32px);

            & > img {
                align-self: center;
            }

            &--items {
                @include d-flex($dir: column, $ai: center, $gap: 16px);
            }

            &--title {
                @include d-flex($dir: column, $ai: center, $gap: 5px);

                &-main {
                    font: $font-primary-28-b;
                    color: $primary-color-black;
                    letter-spacing: -0.3px;
                }

                &-second {
                    font: $font-base-23-l;
                    color: $primary-color-black;

                    strong {
                        font: $font-base-23;
                        color: $primary-color-black;
                        font-weight: 400;
                    }
                }
            }

            &--info {
                font: $font-base-16-l;
                text-align: center;
                color: $primary-color-black;
            }

        }

        &__messages {
            .alert-body {
                &__content {
                    font: $font-base-16-l;
                    color: $primary-color-black;
                    font-style: normal;
                }
            }
        }

        &__products {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            hr {
                height: 1px;
                width: 100%;
                margin: 0;
                padding: 0;
                color: $primary-color-black;
                background-color: $primary-color-black;
                border: none;
            }

            .product-info {
                gap: 0;
            }

            .card {
                &-body {

                    gap: 0.5rem;
                    justify-content: center;

                    &__title {
                        font-weight: 400;
                        color: $primary-color-black;

                        &-type {
                            @include d-flex($gap: 4px, $dir: column);
                            font-weight: 400;
                        }
                    }

                    &__info {
                        margin-top: -.25rem;
                    }
                }
            }

            .card__price {
                @include media-breakpoint-up($mobile-breakpoint) {
                    &:has(+ .card__actions) {
                        order: 1;
                    }
                }

                .price {
                    &:not(.price--previous):not(.price--lowest) {
                        font-weight: 400;
                        letter-spacing: -0.2px;
                        color: $primary-color-black;
                    }

                    &--previous {
                        color: $secondary-color-muted;
                    }

                    &--lowest {
                        font: $font-base-12-l;
                        display: flex;

                        @include media-breakpoint-down($mobile-breakpoint) {
                            width: 6.25rem;
                            text-align: right;
                        }
                    }

                    &-old-new{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        gap: 0;
                    }
                }
            }

            .price-container {
                @include d-flex($dir: column, $ai: flex-end);
            }

            .product__format {
                &--list {
                    @include d-flex($gap: 8px, $ai: flex-start);
                    padding: 0;
                }

                &--badge {
                    @include d-flex($gap: 8px, $ai: center);
                    padding: 0.25rem 0.75rem;
                    background: $primary-color-light;
                    border: $border-gray;
                    border-radius: 0.5rem;

                    font: $font-base-12-l;
                    color: $primary-color-black;
                }
            }
        }

        &__text {
            font: $font-base-16-l;
            text-align: center;
        }

        &__actions {
            display: flex;
            flex-direction: row;
            gap: .5rem;
        }

        &__coupon {
            @include d-flex($ai: center, $jc: space-between);

            &--code {
                font: $font-base-14-l
            }

            &--price {
                font: $font-base-23-l;
            }
        }

        &__sub-price {
            @include d-flex($gap: 16px, $dir: column);
        }

        &__sum {
            @include d-flex($ai: center, $jc: space-between);

            &--label, &--value {
                font: $font-primary-28-b;
                font-weight: 400;
                color: $primary-color-black;
            }
        }

        &__details {

            @include d-flex($dir: row, $gap: 40px, $jc: space-between);

            & > * {
                @include d-flex($dir: column, $gap: 16px);
                flex: 1;
                box-sizing: border-box;
            }


            &--card {
                @include d-flex($dir: column, $gap: 4px);
                background: $primary-color-light;
                border: 1px solid $secondary-color-gray-light;
                border-radius: 0.75rem;

                &.invoice {
                    padding: 0.75rem 1.5rem;
                }

                &.payment {
                    @include d-flex($dir: column, $ai: center);
                    padding: 1rem;
                    font: $font-base-12-l;
                    color: $primary-color-black;

                    img {
                        width: 4rem;
                    }
                }
            }

            &--type {
                font: $font-base-12-l;
                color: $primary-color-black;
            }

            &--address {
                @include d-flex($dir: column);
                font: $font-base-12-l;
                color: $primary-color-black;
            }

            &--name {
                font: $font-base-14;
                font-weight: 400;
                color: $primary-color-black;
            }

            &--note {
                font: $font-base-14-l;
                color: $primary-color-black;
            }
        }

        &__actions {
            @include d-flex($jc: center, $ai: center, $gap: 8px);
        }

        .alert-body__list {
            @include d-flex($dir: column, $gap: 0);

            &-item {
                font: $font-base-16-l;
                margin: 0;
                padding: 0;

                strong {
                    font: $font-base-16;
                }
            }
        }

        hr {
            margin: 1px;
        }
    }

    &-released {
        .section:has(&) {
            align-items: center;
            flex-direction: column;
        }

        .row:has(&) {
            align-items: center;
            flex-direction: column;

            display: flex;
            width: 49.125rem;
            padding-bottom: 0;
            gap: 2rem;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        align-self: stretch;


        &--thumbnail {
            display: flex;
            width: 12.5rem;
            height: 17.5rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
        }

        &--container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.3125rem;

            &-type {
                color: $secondary-color-gray-dark;
                text-align: center;
                font: $font-base-14;
                font-style: normal;
                font-weight: 400;
            }

            &-title {
                color: #000;
                text-align: center;
                font: $font-primary-28-b;
                font-style: normal;
                font-weight: 700;
                letter-spacing: -0.3px;
            }

            &-num {
                color: $primary-color-black;
                text-align: center;
                font-style: normal;
                font: $font-base-20-l;
                line-height: 1.5rem;

                strong {
                    color: $primary-color-black;
                    font: $font-base-20-l;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                }
            }
        }


        &--modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            align-self: stretch;
        }

        &--buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.75rem;
            align-self: stretch;
        }


        &--divider {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.75rem;
            width: 20.875rem;

            background-image: linear-gradient($secondary-color-gray-dark,$secondary-color-gray-dark);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 100% 0.063rem;
            text-align: center;

            span {
                background: $primary-color-white;

                padding: 0 1rem;

                color: $secondary-color-gray-dark;
                font: $font-base-12-l;
                font-style: normal;
                line-height: normal;
            }

        }

        &--button {
            display: flex;
            padding: 0.5rem 1rem;
            align-items: center;
            gap: 0.5rem;
        }
    }
}

.cart:not(.cart_empty), .cart__checkout-col {
    margin-top: 2.5rem;
}

.cart .data-block-radius:first-child {
    margin-top: 0;
}


.card-form-wrapper {
    border-radius: 1.375rem;
    padding: 1rem 1.5rem;
    margin-top: 1rem;

    .buttons-wrapper {
        display: flex;
        justify-content: space-evenly;
        gap: .5rem;

        .btn {
            font-size: .75rem;
            border-radius: .5rem;
            padding: .5rem 1.5rem;
        }
    }

    .form-footer {
        margin-top: 2rem;
    }
}

.processing-payment {
    &-wait {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }

    &-confirm-transaction, &-order-number {
        text-align: center;
        font-family: $primary-font-family-bold;
    }

    &-confirm-transaction {
        font-size: 1.75rem;
        line-height: 2.25rem;
        margin-top: 0;
        margin-bottom: 0.25rem;
        font-weight: 700;
    }

    &-order-number {
        font-size: 1.37rem;
        line-height: 1.75rem;
        margin-bottom: 0;
        font-weight: 700;
    }

    &-logo-container {
        width: 6rem;
        height: 6rem;
        margin: 0 auto 0 auto;
    }

    &-spinner-container {
        padding: 2rem 0 1rem
    }

    &--info {
        margin: 0;
        font-weight: 300;
        text-align: center;
    }
}

.cart-invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-check {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        align-items: baseline;
        gap: .5rem;
    }

    label {
        font: $font-primary-28-b;
        color: $primary-color-black;
        letter-spacing: -0.3px;
        margin: 0;
    }
}

.invoice-data {
    @include gap(1rem);

    &-wrapper {
        .address {
            margin: 0;
            max-width: 100%;
        }
    }
}

.cart-addresses {
    .col-12.col-lg-8 {
        width: 100%;
    }

    .data-block__content {
        display: flex;
        justify-content: start;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .invoice-address {
        width: calc(25% - .75rem);
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                width: 100%;
            }

        .address-block {
            height: 100%;
        }

        .data-block-radius {
            gap: .5rem;
            cursor: pointer;
            border-radius: .75rem;

            .address-info, .address-details, .data-block-actions {
                //text-overflow: ellipsis;
                overflow: hidden;
            }

            &:hover {
                border-color: $button-hover-primary-color;
            }
        }

        .address {
            padding: 1rem 1.5rem;
            height: 100%;
            justify-content: start;
            flex-direction: column;

            &.addresses-block-header__btn {
                min-height: 8.875rem;

                .icon {
                    --icon-size: 3rem;
                }

                span {
                    font-size: .875rem;
                    font-weight: 300;
                    line-height: 1.25rem;
                }

                > div {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    &:last-child {
                        align-items: flex-start;
                    }
                }
            }


            &-details {
                max-width: 100%;
                width: 100%;
                text-align: start;
                font-size: .875rem;
                line-height: 1.25rem;
            }

            &-info {
                margin: 0 0 .5rem;
                flex-direction: column;
                gap: .5rem;
                justify-content: space-between;

                &-content {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: flex-start;
                    gap: .25rem;
                }
            }
        }
    }
}

.card-tokens {
    justify-content: start !important;
}

.select-card, .selected-credit-card {
    & .pay-icon-container {
        margin-top: 0 !important;
    }
}

.select-card {
    & .pay-icon-img {
        max-height: initial !important;
    }
}

.max-height-initial {
    max-height: initial !important;
}


.cart-last-step {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2.5rem;
    }

    &__header {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: .3125rem;
        text-align: center;

        &_first_line {
            font: $font-primary-28-b;
        }

        &_second_line {
            font: $font-primary-22-b;
        }
    }

    &__text {
        max-width: 35.9375rem;
        font: $font-base-16-l;
        text-align: center;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        gap: .5rem;
    }
}

.retry-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 49.375rem;
    margin-right: auto;
    margin-left: auto;

    &__coupon {
        @include d-flex($ai: center, $jc: space-between);

        &--code {
            font: $font-base-14-l
        }

        &--price {
            font: $font-base-23-l;
        }
    }

    &__sub-price {
        @include d-flex($gap: 16px, $dir: column);
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        &--label {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .3125rem;

            &--1 {
                font: $font-primary-28-b;
            }

            &--2 {
                font: $font-primary-22-b;
            }
        }
    }

    &__method-container, &__method-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 100%;
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .5rem;
        gap: 1.5rem;
    }

    .payment-method-wrapper {
        width: 100%;
    }

    .blik_code_input {
        width: 11.3125rem;
    }

    &__products-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        hr {
            height: 1px;
            width: 100%;
            margin: 0;
            padding: 0;
            color: $primary-color-black;
            background-color: $primary-color-black;
            border: none;
        }

        .product-info {
            gap: 0;
        }
    }

    &__products-summary {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        &--line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

#cartAddItemModal {
    .modal {
        &-body {
            max-width: $max-width;
        }

        &-header {
            max-width: 74rem;
            position: relative;
        }

        &-content {
            padding: 3rem 3rem 1.5rem;
            gap: 1.5rem
        }

        &-title {
            font: $font-primary-28-b;
            letter-spacing: -.3px;
            margin: 0;
        }
    }

    .data-block-header {
        margin-bottom: 1rem;
    }
}

#download-product-wrapper {
    .data-block-header {
        width: 100%;
    }
}

.cart-item {
    &-info {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;

        hr {
            background: $secondary-color-gray-light;
            height: 1px;
            margin: 0 -4rem;
        }
    }

    &-content {
        background: $primary-color-light;
        border-radius: .6875rem;
        display: flex;

        [class^=col-] {
            border-left: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            padding: 2rem;
        }

        .card-header__thumbnail {
            height: auto;
        }

        .product {
            &.product__cover {
                width: 4rem;
                height: 4rem;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-align-items: center;
                -webkit-justify-content: center;
                flex-shrink: 0;
                margin-right: 1rem;
                align-self: center;
            }

            &__format--badge {
                line-height: 1rem;
                font-weight: 300;
                width: 3.0625rem;
                height: 1.5rem;
                display: inline-flex;
            }

            &.product__type {
                color: $secondary-color-muted;
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                font-weight: 300;

                &.subcription {
                    margin-bottom: .25rem;
                }
            }

            &.product__price {
                font-weight: 400;
                display: flex;
                line-height: 1.25rem;
                flex-direction: row-reverse;
                gap: .375rem;

                .price--previous {
                    text-decoration: line-through;
                    color: $secondary-color-gray-dark;
                    font-weight: 300;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &--name {
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 1.5rem;

                    a {
                        text-decoration: none;
                    }
                }

                &--authors {
                    font-weight: 300;
                }

                &--publisher {
                    color: $secondary-color-muted;
                    font-size: 0.75rem;
                    font-weight: 300;
                }
            }

            &__subscription, &__mutation {
                color: $primary-color-black;
                font-size: .875rem;
                line-height: 1.25rem;
                font-weight: 300;
                margin-bottom: .5rem;

                strong {
                    font-weight: 400;
                }
            }
        }
    }

    &-actions {
        display: flex;
        justify-content: center;
        gap: 2rem;
        text-align: center;
        align-items: center;
    }
}


.address{
    background: $primary-color-white;
    border-radius: .75rem;
    padding: 1rem 1.5rem;
    display: flex;
    gap: .5rem;


    &.active{
        border: $border-black;
    }

    &.address--add{
        .address-header{
            justify-content: center;
            align-items: center;
        }
    }

    &.editable{
        flex-direction: row;
        justify-content: space-between;
        .address{
            &-container{
                display: flex;
                flex-direction: column;
                gap: .5rem;
                max-width: calc(100% - 6rem);
            }

            &__actions{
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

        }
    }

    .address{


        &-header{
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: .25rem;
            flex: 1 0 0;
            word-break: break-word;
            text-align: left;
            width: 100%;

            &__type{
                font: $font-base-13-l;
            }

            &__title{
                font: $font-base-16;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        &-body{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0;
            width: 100%;

            &__info{
                font: $font-base-14-l;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                max-width: 100%;
                text-align: left;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
        }
    }
}