.btn {
    @include gap(.5rem, row);
    padding: .5rem 1.5rem;
    outline: none;
    font: $font-base-12;
    text-align: center;
    align-items: center;
    border-radius: .5rem;
    border: 0;
    color: $primary-color-black;
    z-index: 1;

    --focus-background-color: #{$button-hover-primary-color};
    --focus-border-color: #{$button-hover-primary-color};
    --focus-text-color: #{$primary-color-white};
    --focus-shadow: #{$btn-focus-box-shadow};

    @media (hover: hover) and (pointer: fine) {
        &:focus, &:hover, &:focus-within, &:focus:active {
            background-color: var(--focus-background-color);
            border-color: var(--focus-border-color);
            color: var(--focus-text-color);
        }
    }

    &:focus, &:focus-within, &:focus:active {
        box-shadow: var(--focus-shadow);
    }

    &:disabled, &[disabled] {
        &, &:active {
            background-color: $secondary-color-muted;
            border-color: $secondary-color-muted;
            color: $primary-color-white;
            box-shadow: none;
        }
    }

    &-primary {
        color: $primary-color-white;
        background-color: $primary-color-black;
    }

    &-secondary {
        padding: .5rem 1rem;
        background-color: $primary-color-light;
        border: $border-secondary-gray;

        --focus-text-color: #{$primary-color-black};
        --focus-background-color: #{$primary-color-light};
    }

    &-lg {
        gap: .75rem;
        padding: .25rem 2rem;
        font: $font-tertiary-28-b;
        border-radius: 1.5rem;
        border: $border-black;
        min-height: calc(0.125rem + 2.25rem + .5rem); //border + line-height + padding (to fix height for button without text)

        --focus-background-color: #{$primary-color-yellow};
        --focus-border-color: #{$primary-color-black};
        --focus-text-color: #{$primary-color-black};
        --focus-shadow: #{$input-btn-focus-box-shadow};
    }

    &-outline {
        &-primary {
            border: $border-black;
            background-color: transparent;

            --focus-background-color: transparent;
            --focus-text-color: #{$button-hover-primary-color};
        }

        &-secondary {
            padding: .5rem 1rem;
            border: $border-secondary-gray;
            background-color: $primary-color-white;

            --focus-background-color: #{$primary-color-white};
            --focus-text-color: #{$primary-color-black};

            &.btn-lg {
                padding: 1rem 1.5rem;
                gap: 1rem;
                border-radius: .75rem;
                font: $font-base-16;

                --focus-border-color: #{$button-hover-primary-color};
                --focus-shadow: #{$btn-focus-box-shadow};
            }
        }
    }

    &-danger {
        color: $primary-color-white;
        background-color: $primary-color-red;
    }

    &-rounded {
        background-color: $primary-color-white;
        border-radius: 3rem;
        border: 0.1875rem solid $primary-color-light;

        --focus-border-color: #{$primary-color-light};
    }

    &-icon {
        padding: .5rem;
        background-color: transparent;

        --focus-background-color: transparent;

        &--rounded {
            color: $primary-color-white;
            background-color: $primary-color-black;
            border-radius: 3rem;
            border: 0.125rem solid $primary-color-light;

            --focus-border-color: #{$primary-color-light};
        }
    }

    &-sticker {
        padding: .5rem 1rem;
        gap: 1rem;
        border: $border-black;
        border-bottom: 0;
        border-radius: 1rem 0 0 0;
        background-color: transparent;
        font: $font-tertiary-16-b;
        position: relative;

        &:after {
            content: '';
            border: $border-light;
            position: absolute;
            bottom: 0;
            left: -1px;
            right: -1px;
        }

        --focus-background-color: transparent;
        --focus-text-color: #{$button-hover-primary-color};
    }

    &-link {
        padding: .5rem 0;
        font: $font-base-14-l;
        text-decoration: none;
        transition: .2s ease-in-out 0s;

        --focus-background-color: transparent;
        --focus-text-color: #{$button-hover-primary-color};
        --focus-shadow: none;

        &:disabled, &[disabled] {
            background-color: transparent;
            color: $primary-color-black;
        }

        &--underlined {
            border-bottom: 1px solid;
            border-radius: 0;
        }

        &--font16 {
            font-size: 1rem;
        }
    }

    &-group {
        &-vertical {
            display: flex;
            flex-direction: column;
            padding: 0;

            &__content {
                display: flex;
                flex-direction: column;
                padding: 0;
                gap: .5rem;
            }

            .btn-check-box {
                border-bottom: $border-secondary-gray-light;
                --focus-border-color: #{$secondary-color-gray-light};
                padding: 1rem 2rem 1.25rem;

                &:first-child {
                    border-top-left-radius: inherit;
                    border-top-right-radius: inherit;
                }

                &:last-child {
                    border-bottom-left-radius: inherit;
                    border-bottom-right-radius: inherit;
                }
            }
        }
    }

    &-check-box {
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        gap: 0rem .5rem;
        --bs-gutter-x: 0;
        align-self: stretch;
        cursor: pointer;
        justify-content: center;

        &:not(.btn-check-box--outline):last-child {
            border-bottom: 0;
        }

        .form-check-input {
            cursor: pointer;
        }

        &__title {
            font: $font-base-14;
            word-break: break-word;
        }

        &__info {
            font: $font-base-12-l;
        }

        &__price {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .price {
                order: 3;
                font-weight: 500;
                font-size: 1.1875rem;
                line-height: 1.5rem;

                &--previous, &--discount {
                    font: $font-base-14-l
                }

                &--previous {
                    order: 1;
                }

                &--discount {
                    order: 2;
                }

                &--lowest {
                    order: 4;
                    font: $font-base-12-l;
                }
            }

            &--lowest {
                width: 100%;
            }
        }

        &__variants {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin-top: 1rem;
        }

        &.active {
            background: $primary-color-light;

            .btn-check-box {
                &__title {
                    font-weight: 600;
                }
            }
        }

        --focus-border-color: #{$button-hover-primary-color};
        --focus-shadow: #{$btn-focus-box-shadow};

        &:not(.active) {
            &:hover, &:focus, &:focus-within, &:focus:active {
                border-color: var(--focus-border-color);

                .form-check-input {
                    border-color: $primary-color-orange;
                }
            }

            &:focus, &:focus-within, &:focus:active {
                box-shadow: var(--focus-shadow);
            }
        }

        &--outline {
            border-radius: .75rem;
            border: $border-secondary-gray;
            padding: .75rem 1.5rem;
            background: $primary-color-white;

            &.active {
                background: $primary-color-white;
                border-color: $primary-color-black;
            }

            .btn-check-box {
                &__info {
                    font: $font-base-11-l;
                }
            }
        }
    }


    &--prop {
        cursor: default !important;
        --focus-background-color: initial;
        --focus-border-color: initial;
        --focus-text-color: initial;
        --focus-shadow: initial;
    }

    &-tooltip{
        border: none;
        padding: 0;
        background: transparent;
        height: 1.25rem;
    }
}

.product-mutation-btn {
    text-align: left;
    max-width: 22.25rem;

    .mutation-name {
        width: calc(100% - 1rem - 1px);
        word-break: break-word;
        font-weight: 400;
    }
}
