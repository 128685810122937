@import "~swiper/scss";
@import "~swiper/scss/navigation";

.swiper {
    --swiper-navigation-size: $carousel-arrow-size-y;
    //Needed to keep shadows in card slides, should not be higher than "spaceBetween"
    padding-left: 2.5rem;
    margin-left: -2.5rem;
    @include media-breakpoint-down($mobile-breakpoint-banner) {
        padding: 0;
        margin: 0;
    }
    .swiper-slide{
        &-desktop{
            display: block;
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                display: none;
            }
        }
        &-mobile{
            display: none;
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                display: block;
            }
        }
    }

    &-button {
        &-next, &-prev {
            height: $carousel-arrow-size-y;
            width: $carousel-arrow-size-x;
            margin-top: 0;

            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                display: none;
            }

            &:after {
                content: '';
            }
        }

        &-next, .swiper-rtl &-prev {
            right: 0;
        }

        &-prev, .swiper-rtl &-next {
            left: 0;
        }

        &-disabled {
            display: none;
        }
    }

    &-slide {
        display: block;
        margin: 0;
        height: auto;
    }

    &-pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: .5rem auto 0;


        &__show-all {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 2.5rem;
            text-align: right;
            margin-top: -2.5rem;
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                margin: 0;
                text-align: center;
                justify-content: center;
            }
        }

        &__bullet {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: .5rem 0;
            gap: .75rem;
            cursor: pointer;
            width: 2.5rem;
            height: 2.5rem;
            @include media-breakpoint-down($mobile-breakpoint-banner) {
                height: fit-content;
                padding-top: 0;
                padding-bottom: .375rem;
            }

            &-active, &:hover {
                .swiper-pagination__bullet-dot {
                    background: $primary-color-orange;
                }
            }

            &-active {
                .swiper-pagination__bullet--inside {
                    border: $border-secondary-gray;
                }
            }

            &--inside {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            &-dot {
                width: .5rem;
                height: .5rem;
                background: $secondary-color-gray;
                border-radius: 50%;
            }
        }
    }
}

.carousel--products {
    .swiper-pagination {
        @media (min-width: $max-mobile-width) {
            display: none;
        }
    }
}