@mixin gap($gap-value, $direction: column) {
    display: flex;
    flex-direction: $direction;
    gap: $gap-value;
}

@mixin d-flex($dir: null, $gap: null, $ai: null, $ac: null, $jc: null, $ji: null) {
    display: flex;
    @if $dir {
        flex-direction: $dir;
    }
    @if $gap {
        gap: $gap;
    }
    @if $ac {
        align-content: $ac;
    }
    @if $ai {
        align-items: $ai;
    }
    @if $ji {
        justify-items: $ji;
    }
    @if $jc {
        justify-content: $jc;
    }
}
