.newsletter {
    &-sign-up {
        &__e-mail {
            width: 25rem;
            max-width: 100%;
        }

        p {
            margin: 0;
        }

        .invalid-feedback {
            position: absolute;
        }

        .card--promo {
            flex-direction: row;
            padding: 1.5rem 3.875rem;

            @include media-breakpoint-down($mobile-breakpoint) {
                padding: 1.5rem 2rem;
            }

            &:before {
                height: 69.8125rem;
                width: 69.8125rem;
                bottom: -51.5rem;
                right: 16.1875rem;
                left: unset;
            }

            .card {
                &-footer {
                    max-width: 100%;
                    flex-direction: column;

                    &__info {
                        max-width: 100%;
                        @include gap(1rem, row);
                        align-items: center;
                        flex-wrap: wrap;

                        .form-floating {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}