$enable-negative-margins: true;

$base-font-family: "Source Sans Pro", sans-serif;
$primary-font-family: "SaolStandard", serif; //@Deprecated
$primary-font-family-bold: "SaolStandard-Bold", sans-serif;
$primary-font-family-medium: "SaolStandard-Medium", sans-serif;
$secondary-font-family: "Open sans", sans-serif;
$tertiary-font-family: "Frank Ruhl Libre", sans-serif;
$fancy-font-family: "Suisse Works", serif;
$system-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$first-font-family: $primary-font-family-bold; //@Deprecated

$primary-color-white: #ffffff;
$primary-color-black: #1f1f1f;
$primary-color-black-deep: #000000;
$primary-color-light: #f6f5f3;
$primary-color-muted: #171717;
$primary-color-orange: #EC5D2B;
$primary-color-yellow: #fcbf3d;
$primary-color-blue: #b8c5de;
$primary-color-red: #D82017;
$primary-color-disabled: #757574;
$primary-color-green: #93CBC4;

$secondary-color-muted: #7B7B7B;

$secondary-color-gray-dark: #B1B1B1;
$secondary-color-gray: #D8D8D8;
$secondary-color-gray-light: #E6E6E6;
$secondary-color-green: #29B473;

$button-color: $primary-color-black;
$button-color-muted: #B1B1B1;
$button-hover-primary-color: $primary-color-orange;

$link-color: #808080;
$border-color: #d3d3d3;

$border-black: 1px solid $primary-color-black;
$border-white: 1px solid $primary-color-white;
$border-gray: 1px solid $secondary-color-gray-light;
$border-light: 1px solid $primary-color-light;
$border-dark: 1px solid #1F1F1F4D;
$border-muted-light: 1px solid $secondary-color-gray-dark;
$border-orange: 1px solid $primary-color-orange;

$border-secondary-gray: 1px solid $secondary-color-gray;
$border-secondary-gray-light: 1px solid $secondary-color-gray-light;


$font-base-28-l: 300 1.75rem/2.25rem $base-font-family;
$font-base-27-l: 300 1.6875rem/2.25rem $base-font-family;
$font-base-23: 1.4375rem/2rem $base-font-family;
$font-base-23-l: 300 $font-base-23;
$font-base-20-l: 300 1.25rem $base-font-family;
$font-base-18: 1.125rem/1.5rem $base-font-family;
$font-base-18-l: 300 $font-base-18;
$font-base-16: 1rem/1.5rem $base-font-family;
$font-base-16-l: 300 $font-base-16;
$font-base-16_25-l: 400 1rem/1.25rem $base-font-family;
$font-base-14: .875rem/1.25rem $base-font-family;
$font-base-14-l: 300 $font-base-14;
$font-base-13: .8125rem/.875rem $base-font-family;
$font-base-13-l: 300 $font-base-13;
$font-base-12: .75rem/1rem $base-font-family;
$font-base-12-l: 300 $font-base-12;
$font-base-12_938-l: 300 .75rem/.938rem $base-font-family;
$font-base-11: .6875rem/.75rem $base-font-family;
$font-base-11-l: 300 $font-base-11;

$font-primary-42-b: 2.625rem/3rem $primary-font-family-bold;
$font-primary-36-b: 2.25rem/2.25rem $primary-font-family-bold;
$font-primary-28-b: 1.75rem/2.25rem $primary-font-family-bold;
$font-primary-22-b: 1.375rem/1.75rem $primary-font-family-bold;
$font-primary-18-b: 1.125rem/1.75rem $primary-font-family-bold;
$font-primary-17-b: 1.0625rem/1.25rem $primary-font-family-bold;
$font-primary-16-b: 1rem/1.5rem $primary-font-family-bold;

$font-primary-14-m: 500 1.25rem/1.5rem $primary-font-family-medium;

$font-tertiary-28: 1.75rem/2.25rem $tertiary-font-family;
$font-tertiary-28-b: 700 $font-tertiary-28;
$font-tertiary-16: 1rem/1.25rem $tertiary-font-family;
$font-tertiary-16-b: 700 $font-tertiary-16;

$badge-color-blue: #B8C5DE;
$badge-color-pink: #EF8CA3;

$form-check-input-width: 1.2em;
$form-check-min-height: 1.2em;
$form-check-input-border: $border-black;
$form-check-input-bg: none;
$form-check-input-checked-bg-color: $primary-color-white;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'><circle r='1' fill='#{$primary-color-black}'/></svg>");
$form-check-input-checked-border-color: $primary-color-black;

$form-floating-height: 3.5rem;

$input-border-radius: .625rem;

$form-label-color: $primary-color-black;

$form-feedback-valid-color: $secondary-color-green;
$form-feedback-invalid-color: $primary-color-red;
$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-neutral-color: #b1b1b1;
$input-height-inner-half: 0.375rem;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6'><circle fill='#{$form-feedback-icon-valid-color}' cx='3' cy='3' r='3'/></svg>");
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6'><circle fill='#{$form-feedback-icon-invalid-color}' cx='3' cy='3' r='3'/></svg>");

$icon-base-size: 1.25rem;

$transition-collapse: all 0s ease 0s;
$transition-collapse-width: all 0s ease 0s;

$content-margin-top: 6.25rem;

$container-max-widths: (
    sm: 33.75rem,
    md: 45rem,
    lg: 61rem,
    xl: 71.25rem,
    xxl: 80rem
);

$grid-breakpoints: (
    xxs:0,
    xs: 22.5rem,
    ss: 29.75rem, //small small
    sm: 33.75rem,
    md: 45rem,
    lg: 61rem,
    xl: 71.25rem,
    xxl: 80rem
);

$mobile-breakpoint: lg;
$mobile-breakpoint-banner: lg;
$max-mobile-width: map-get($grid-breakpoints, $mobile-breakpoint);
$max-width: map-get($grid-breakpoints, xxl);

$theme-colors: (
    "primary": $primary-color-black,
    "success": $primary-color-green,
    "info": $primary-color-blue,
    "warning": $primary-color-yellow,
    "danger": $primary-color-orange,
    "error": $primary-color-red,
);

$select-color: transparent !default;

/** accordion */
$accordion-button-icon: url("data:image/svg+xml,<svg width='48' height='48' viewBox='0 0 48 48' fill='#{$primary-color-black}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.87122 18.0854L6.19278 18.2784L23.9998 28.9626L41.8069 18.2784L42.1285 18.0854L42.5143 18.7286L42.1928 18.9215L24.1928 29.7215L23.9998 29.8373L23.8069 29.7215L5.80691 18.9215L5.48535 18.7286L5.87122 18.0854Z' fill='#1F1F1F'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='48' height='48' viewBox='0 0 48 48' fill='#{$primary-color-black}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.87122 18.0854L6.19278 18.2784L23.9998 28.9626L41.8069 18.2784L42.1285 18.0854L42.5143 18.7286L42.1928 18.9215L24.1928 29.7215L23.9998 29.8373L23.8069 29.7215L5.80691 18.9215L5.48535 18.7286L5.87122 18.0854Z' fill='#1F1F1F'/></svg>");

$cart-checkout-shadow: 0 -1px .25rem 0 rgba(0, 0, 0, 0.25);

/** bootstrap form variables */
$dropdown-bg: $primary-color-white;
$dropdown-link-hover-bg: $primary-color-light;

$form-check-margin-bottom: 0;

$input-btn-focus-color: $secondary-color-muted;
$input-btn-focus-color-opacity: .24;
$input-btn-focus-box-shadow: 0 0 0 .25rem rgba($input-btn-focus-color, $input-btn-focus-color-opacity);
$btn-focus-box-shadow: 0 0 0 .25rem rgba($button-hover-primary-color, $input-btn-focus-color-opacity);

$input-disabled-bg: $primary-color-light;
$input-border-color: $secondary-color-gray;
$input-focus-border-color: $secondary-color-muted;
$input-border-radius: .5rem;
$input-font-size-sm: 1rem;
$line-height-sm: 1.5rem;

$modal-content-border-width: 0;
$modal-content-border-radius: .375rem;
$modal-content-box-shadow-xs: -.25rem .25rem .5rem rgba(0, 0, 0, 0.2);
$modal-content-box-shadow-sm-up: $modal-content-box-shadow-xs;

$modal-backdrop-bg: $primary-color-light;
$modal-backdrop-opacity: .8;

$modal-md: 26.5625rem;

/** card variables **/
$card-border-width: 0;

/** selectize variables */
$selectize: 'selectize' !default;

$select-arrow-size: 0;
$select-arrow-offset: 0;
$select-arrow-background-size: 1rem;
$select-arrow-distance: .75rem;

$select-color-border: $input-focus-border-color;
$select-border: 1px solid $select-color-border !default;

$select-color-input: $select-color;
$select-color-input-full: $select-color;
$select-color-input-error: $select-color;
$select-color-input-error-focus: $select-color;

$select-color-text: $primary-color-black;

$select-color-item: $select-color-text;
$select-color-item-active-text: $select-color-text;

$select-line-height: 1.25rem;

$select-max-height-dropdown: 14rem;

$select-dropdown-arrow-size: .625rem;
$select-dropdown-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
$select-dropdown-radius: .375rem;

$select-padding-y: .5rem;
$select-padding-x: 1rem;
$select-padding-arrow-y: .375rem;
$select-padding-arrow-x: calc(#{$select-arrow-background-size} / 2 + #{$select-arrow-distance});
$select-padding-dropdown-item-y: .25rem;

$select-margin-dropdown-content-y: 1.5rem;
$select-margin-dropdown-content-x: 1rem;