.download-types {
    width: 23.5625rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;
    border: 1px solid $secondary-color-gray;
    border-radius: 1rem;

    &__subtitle {
        font: $font-base-14-l;
        color: $primary-color-black;
    }
}

.download-product {
    &__links-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--item {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: stretch;
            padding: .75rem;
            gap: .75rem;
            border-bottom: $border-secondary-gray-light;

            &:last-child {
                border-bottom: none;
            }

            &-description {
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem
            }

            &-actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: .5rem 0;
                gap: .5rem;

                .download-product-btn {
                    padding: 0;
                    border: none;
                    font: $font-base-14-l;

                    &:focus {
                        box-shadow: none;
                        color: $primary-color-black;

                    }

                    @include media-breakpoint-down($mobile-breakpoint) {
                        &.icon-container {
                            &:focus, &:hover {
                                .icon {
                                    background-color: $primary-color-black !important;
                                }
                            }
                        }

                        &:active, &:hover {
                            background-color: transparent;
                            color: $primary-color-black;
                        }
                    }


                    &--wait {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &__info--too-long {
        .alert-body__text {
            font: $font-base-14-l;
        }
    }

    &-btns-container {
        @include media-breakpoint-down($mobile-breakpoint) {
            display: flex;
            justify-content: center;
            gap: 1rem;

            .download-product:active {
                background: inherit;
                border-color: inherit;
                color: inherit;
            }
        }
    }
}