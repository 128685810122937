.toast {
    display: flex;
    align-items: flex-start;
    background-color: $primary-color-white;
    min-width: 30rem;
    border: $border-secondary-gray;
    box-shadow: .1875rem .1875rem 0 $secondary-color-gray-light;
    border-radius: .6875rem;
    flex-direction: column;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    &-container {
        position: fixed;
        top: 2.5rem;
        z-index: 1090;
        width: 100%;
    }

    &-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .75rem 1.5rem;
        gap: .75rem;
        width: 100%;
        align-self: stretch;
        flex-grow: 1;
        background-color: $primary-color-white;

        &__heading {
            flex-grow: 1;
            flex-basis: min-content;
            font: $font-base-16-l;
            color: $primary-color-black;
        }
    }

    .progress {
        height: .375rem;
        width: 100%;
        border-radius: 0;
        background-color: $primary-color-white;

        &-bar {
            width: 100%;
            transition: none;
        }
    }
    
    @media (max-width: map-get($grid-breakpoints, sm)) {
        min-width: 100%;

        &-body{
            padding: .75rem;
            gap: .5rem;
        }

        .toast-body__heading{
            max-width: calc(100% - 4rem - 2.5rem);
        }
    }
}