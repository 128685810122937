.alert {
    display: flex;
    align-items: flex-start;
    padding: .75rem 1.5rem;
    gap: .75rem;
    border: $border-gray;
    box-shadow: .1875rem .1875rem 0 $secondary-color-gray-light;
    border-radius: 1.375rem;
    margin-bottom: 0;

    &-body {
        display: flex;
        flex-grow: 1;
        align-self: stretch;
        justify-content: space-between;

        &__content {
            font: $font-base-14;
            display:flex;
            flex-grow: 1;
            gap: .5rem;
            flex-direction: column;
            justify-content: center;
        }

        &__heading{
            font: $font-base-16;
            color: $primary-color-black;

        }

        &__text{
            font-weight:300;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $primary-color-black;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:empty{
                display:none;
            }

            form{
                display: flex;
                flex-direction: column;
                gap: .5rem;
            }

            .form-check{
                margin-bottom: 0;
            }

            .form-check-label{
                margin:0;
            }
        }

        &__icon{
            display: flex;
        }


        &__list {
            padding-left: 1.125rem;
            margin: -1px 0 1px;

            &-item {
                padding-left: .375rem;
                font-weight: 300;

                &-content {
                    margin: 1px 0 -1px;
                    float: left;
                }

                &::marker {
                    font-size: .5625rem;
                    line-height: 1;
                }
            }
        }
    }
}

.alert-box-container {
    margin-top: 2.5rem;

    .alert {
        background: $primary-color-white;

        p {
            margin: 0;
        }
    }
}
.box-password-setup{
    .alert{
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        .alert-body__list-item{
            line-height: 1.5rem;
        }
    }
}