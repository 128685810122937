$mobile-breakpoint: lg;

footer {
    font-family: $base-font-family;
    background-color: var(--background-color);

    .logo {
        &-box {
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
        }

        &-nexto {
            width: 5.875rem
        }

        &-books {
            width: 4.6875rem;
            margin-left: 0.625rem;
        }
    }

    a {
        text-decoration: none;
    }

    .footer {
        &-links {
            a {
                color: #000000;
                font-size: 0.9375rem;
                font-weight: 300;
            }
        }

        &-media {
            a {
                line-height: 1rem;
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    height: 1rem;
                }
            }
        }

        &__copyright, &__powered-by {
            color: $primary-color-black-deep;
            font-size: 0.813rem;
            font-weight: 300;
            line-height: 1.063rem;

            @include media-breakpoint-down($mobile-breakpoint) {
                text-align: center;
            }

            a {
                text-decoration: underline;
            }
        }
    }

}
.footer__copyright {
    color: $primary-color-black-deep;
    font-size: 0.813rem;
    font-weight: 300;
    line-height: 1rem;
}

.footer__powered-by {
    color: $primary-color-black-deep;
    font-size: 0.813rem;
    font-weight: 300;
    line-height: 1rem;
    text-align: end;
}
