.card {
    background: transparent;
    height: 100%;
    //should be changed to media-breakpoint-up?
    @media (min-width: calc($max-mobile-width + 1px)) {
        &:hover {
            .card-header__actions {
                opacity: 1;
            }
        }
    }

    &--grid-list {
        min-height: 27.25rem;
        text-decoration: none;
        border: none;
        align-items: center;
        gap: .75rem;
        padding: .5rem;

        .card-body__info{
            flex-direction: column;
        }
    }


    &-header {
        display: flex;
        align-items: center;
        background: none;
        border-bottom: none;
        padding: 3rem 0 0;
        position: relative;

        &__badge {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }

        &__thumbnail {
            display: flex;
            justify-content: center;
            align-items: center;

            &-image {
                box-shadow: -1.125rem 0 2.25rem -1.125rem rgba(0, 0, 0, 0.16), -1.125rem 0 1.125rem -1.125rem rgba(0, 0, 0, 0.16);
            }
        }

        &__actions {
            position: absolute;
            opacity: 0;
            transition: opacity .2s ease;
            top: 3.5rem;
            right: .5rem;
            display: flex;
            flex-direction: column;
            gap: .25rem;
            width: 2.5rem;
            align-items: flex-end;

            &-item {
                align-items: center;
                background: none;
                border: none;
                display: flex;
                height: 2.5rem;
                padding: .5rem;
                justify-content: center;
            }
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        align-items: center;
        text-align: center;
        border-top: 1px solid $secondary-color-gray-light;
        padding: 1rem 0 0;
        gap: .25rem;

        &__badges {
            flex-wrap: wrap;
            display: inline-flex;
            gap: .5rem;
        }

        &__price {
            display: flex;
            justify-content: center;
            gap: .5rem;
            line-height: 1.5rem;
            flex-wrap: wrap;

            .price {
                margin-right: 0;
                font-weight: 500;
                font-size: 1.25rem;
                font-family: $primary-font-family;
                line-height: 1.5rem;

                &--previous {
                    text-decoration: line-through;
                    font-size: 1.125rem;
                    color: $secondary-color-gray-dark;
                    font-weight: 300;
                    line-height: 1.5rem;
                }

                &-points {
                    display: none;
                    font: $font-base-16-l;
                    flex: 0 0 100%;
                }

                &--lowest {
                    font: $font-base-12-l;
                    display: flex;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        flex-basis: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        &__type, &__mutation {
            font: $font-base-14;
            color: $secondary-color-muted;
        }

        &__title {
            font-size: 1.125rem;
            line-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            word-break: break-word;
        }

        &__info {
            font: $font-base-16-l;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;
            display: flex;
            gap: .5rem;
        }

        &__mutation {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 300;
        }

    }

    &-footer {
        background: none;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
    }

    &--grid-list {
        min-height: 26rem;
        align-items: center;
        gap: .75rem;
        padding: .5rem;

        .card {
            &-header {
                width: 100%;
                justify-content: center;

                &__thumbnail {
                    max-width: 10rem;
                    height: 13.75rem;
                }
            }

            &-body {
                &__price {
                    order: 5;
                }

                &__type {
                    order: 1;
                }

                &__title {
                    order: 2;
                }

                &__info {
                    order: 3;
                }

                &__mutation {
                    order: 4;
                }
            }
        }
    }

    &--featured {
        flex-direction: row;
        gap: .75rem;
        justify-content: center;
        align-items: flex-start;

        @include media-breakpoint-down($mobile-breakpoint) {
            flex-wrap: wrap;
        }

        .card {
            &-header {
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                flex: 1 1 0;

                &__thumbnail {
                    max-width: 12.5rem;
                    height: 17.5rem;
                    padding: 0 .625rem;
                    align-items: flex-start;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        height: auto;
                    }
                }

                &__actions {
                    left: 0;
                    top: 0;
                    right: auto;
                    position: relative;

                }
            }

            &-body {
                border-top: none;
                align-items: flex-start;
                max-width: 19.75rem;
                text-align: left;
                height: 17.5rem;
                justify-content: center;
                box-sizing: content-box;
                padding: 3rem 0 3rem 1rem;
                @include media-breakpoint-down($mobile-breakpoint) {
                    height: auto;
                    padding: 1rem 0 3rem 1rem;
                    width: 100%;
                    align-items: center;
                    text-align: center;
                }

                &__price {
                    justify-content: flex-start;
                }

                &__info {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                }

                &__title {
                    font-size: 1.75rem;
                    line-height: 2.25rem;
                    font-family: $primary-font-family-bold;
                }
            }
        }
    }

    &--small {
        gap: 1rem;
        padding: 0;
        flex-direction: row;

        .card {
            &-header {
                padding: 0;

                &__thumbnail-image {
                    max-height: 6.875rem;
                    max-width: 5rem;
                }
            }

            &-body {
                border: 0;
                padding: 0;
                text-align: left;
                align-items: flex-start;
                justify-content: center;

                &__title {
                    font: $font-base-14;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                }

                &__info {
                    font: $font-base-13-l;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    margin: 0;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        flex-direction: column;
                    }
                }

                @include media-breakpoint-down($mobile-breakpoint) {
                    &__price{
                        justify-content: flex-start;
                    }
                }
            }
        }

        &-my-product {
            .card-body {
                &__info {
                    -webkit-line-clamp: 2;
                    line-clamp: 2;

                    & > div {
                        font: $font-base-13-l;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                    }
                }
            }
        }
    }
    &--mini {
        gap: 1rem;
        padding: 0;
        padding-top: 2rem;
        padding-bottom: 3rem;
        flex-direction: row;

        .card {
            &-header {
                padding: 0;

                &__thumbnail-image {
                    max-height: 3rem;
                    max-width: 3rem;
                }
            }

            &-body {
                border: 0;
                padding: 0;
                text-align: left;
                align-items: flex-start;
                justify-content: center;

                &__title {
                    font: $font-base-16;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                }

                &__info {
                    font: $font-base-13-l;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    margin: 0;
                }
            }
        }

        &-my-product {
            .card-body {
                &__info {
                    -webkit-line-clamp: 2;
                    line-clamp: 2;

                    & > div {
                        font: $font-base-13-l;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                    }
                }
            }
        }
    }

    &--details {
        gap: 2.5rem;
        padding: 2.5rem;
        flex-direction: row;
        border: none;
        flex-wrap: nowrap;
        @include media-breakpoint-down($mobile-breakpoint) {
            flex-wrap: wrap;
            padding: 2.5rem 1.25rem;
            gap: 2.5rem 0;
        }
        &-top {
            padding: 3rem 0 2.5rem 0;

            .card {
                &-header__thumbnail, &-body, &__actions {
                    padding: 0 !important;
                }

                &-body {
                    gap: .5rem !important;
                }

            }

        }

        &.card--details--small{
            padding: 3rem 2.5rem;
            justify-content: space-between;
            @include media-breakpoint-down($mobile-breakpoint) {
                flex-wrap: wrap;
            }

            .card{
                &-header__thumbnail,&-body,&__actions{
                    padding: 0;
                }

                &-header__thumbnail{
                    max-width: 5.625rem;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        max-width: 10rem;
                    }
                }
            }

        }

        .card{
            &__actions{
                padding: 3rem 0 0;
                @include media-breakpoint-down($mobile-breakpoint) {
                    order: 2;
                }
            }

            &-body {
                border-top: none;
                align-items: flex-start;
                gap: 3rem;
                justify-content: flex-start;
                padding: 3rem 0 0;
                @include media-breakpoint-down($mobile-breakpoint) {
                    padding: 0;
                    order: 3;
                }


                &__content {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    text-align: left;
                    justify-content: flex-start;
                    gap: .5rem;
                    color: $primary-color-black;
                    align-items: flex-start;

                    &.product-variants-content {
                        gap: 1.5rem;
                    }

                    > .card-body__content {
                        flex-direction: row;
                        align-items: center;
                    }

                    .card-body__type {
                        color: $primary-color-black;
                    }
                }

                &__type {
                    font-weight: 300;
                    font-size: .875rem;
                    line-height: 1.25rem;
                }

                &__title {
                    font-size: 2.625rem;
                    line-height: 3rem;
                    font-weight: 700;
                    letter-spacing: -.7px;
                    font-family: $primary-font-family-bold;
                }

                &__subtitle {
                    font-weight: 300;
                    line-height: 2.25rem;
                    font-size: 1.6875rem;
                }

                &__info {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    font-weight: 300;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        white-space: nowrap;
                        justify-content: flex-start;
                    }
                }

                &__price {
                    text-align: left;
                    flex-direction: column;
                    gap: 0;

                    .price {
                        letter-spacing: -.3px;
                        margin-right: .9375rem;
                        font: $font-primary-28-b;

                        &--previous {
                            margin-left: 0;
                            margin-right: .25rem;
                            font: $font-base-27-l;
                            color: $secondary-color-muted;
                        }

                        &--discount {
                            color: $secondary-color-muted;
                            margin-right: 0;
                            display: unset;
                            font: $font-base-27-l;
                        }

                        &-points {
                            font: $font-base-16-l;
                            display: flex;
                        }

                        &--lowest {
                            font: $font-base-12-l;
                            display: flex;
                        }
                    }
                }

                &__actions {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: .75rem;
                    width: 100%;
                }

                &__details {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    align-items: flex-start;
                    width: fit-content;
                    width: -moz-fit-content;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        width: 100%;
                    }
                }
            }

            &-header {
                gap: 1rem;
                padding: 0;
                display: flex;
                flex-direction: column;
                isolation: isolate;
                @include media-breakpoint-down($mobile-breakpoint) {
                    gap:0;
                    order: 1;
                    width: calc(100% - 2.5rem);
                }

                &__thumbnail {
                    padding: 3rem 2.5rem 1.5rem;
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    flex-direction: column;
                    align-items: stretch;

                    &-image {
                        flex: 1 1 auto;
                        box-shadow: none;
                        width: 100%;
                        max-width: unset;
                        z-index: 1;
                    }

                    &-actions {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        button {
                            position: absolute;
                            z-index: 100;
                        }
                    }
                }
            }
        }
    }

    &--table {
        display: flex;
        width: 100%;
        flex-direction: row;

        .card {
            &-header {
                padding: 1rem 2rem;
                border-right: 1px solid $primary-color-white;
                gap: 1rem;

                &__thumbnail {
                    width: 4rem;
                    aspect-ratio: 1/1;
                }

                &__title {
                    font-weight: 400;
                    text-decoration: none;
                }

                &__title, &__author {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    font-family: $base-font-family;
                }

                &__author {
                    font-weight: 300;
                }

                &__publisher {
                    font: $font-base-12-l;
                    color: $secondary-color-muted;
                    margin-top: -.125rem;
                }
            }

            &-body {
                border-top: none;
                padding: 0;
                gap: 0;

                &__type{
                    font-weight: 300;
                }

                &__info {
                    font-size: .875rem;
                    line-height: 1.25rem;
                    font-weight: 300;
                    width: 100%;
                    text-align: left;
                    margin-top: -.25rem;
                    flex-direction: column;
                }

                &__content {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .card-body__content {
                        align-items: center;
                        padding: 1rem 2rem;
                        justify-content: flex-end;
                        width: 50%;
                    }
                }

                &__details {
                    padding: 1rem 2rem;
                    border-right: 1px solid $primary-color-white;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: .5rem;
                }

                &__price {
                    flex-direction: column;
                    gap: .25rem;

                    .price {
                        font: $font-base-16;

                        &-container {
                            display: flex;
                            flex-direction: row-reverse;
                            gap: .5rem;
                            flex-wrap: wrap;
                            align-items: center;
                        }

                        &--previous {
                            font-weight: 300;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            text-decoration-line: line-through;
                            color: $secondary-color-muted;
                        }

                        &--discount {
                            display: none;
                        }

                        &-points {
                            display: flex;
                            font-size: .75rem;
                            line-height: .9375rem;
                            font-weight: 300;
                            color: $primary-color-black;
                            text-align: right;
                            justify-content: end;
                        }

                        &--lowest {
                            font: $font-base-12-l;
                            display: flex;
                        }
                    }
                }

                .badge--light{
                    background: $primary-color-white;
                }
            }
        }
    }

    &--list {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem 0;
        gap: 1.5rem;

        @include media-breakpoint-down($mobile-breakpoint) {
            align-items: start;
            row-gap: 0px;
        }

        &.row {
            margin-left: 0;
            margin-right: 0;
        }

        .card {
            &-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 0;
                gap: .25rem;
                flex: 1 0 0;
                border: 0;
                text-align: left;
                @include media-breakpoint-down($mobile-breakpoint) {
                    justify-content: start;
                }

                &__badge-list {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0;
                    gap: .5rem;
                    margin-top: .25rem;
                }

                &__info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0;
                    gap: .25rem;
                }
            }
            &-header {
                padding: 0;

                &__thumbnail {
                    &-image {
                        max-width: 4.6875rem;
                        max-height: 6.875rem;
                    }
                }
            }

            &__price, &__actions {
                padding: 0;
            }

            &__actions{
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                gap: 0.75rem;

                @include media-breakpoint-down($mobile-breakpoint) {
                    flex-basis: 100%;
                    flex-grow: 0;

                    .product-delete {
                        display: none;
                    }
                }
            }

            &__price {
                display: flex;
                flex-direction: column;
                gap: .5rem;

                .price {
                    font: $font-primary-22-b;

                    &--previous, &--discount {
                        font: $font-base-18-l;
                    }
                }

                .product-delete{
                    display: none;
                    @include media-breakpoint-down($mobile-breakpoint-banner) {
                        display: flex;
                    }
                }
            }
        }
    }

    &--promo {
        background-color: $secondary-color-green;
        justify-content: center;
        align-items: center;
        isolation: isolate;
        overflow: hidden;
        flex-wrap: wrap;
        border-radius: .6875rem;
        padding: 1.5rem 3rem;
        gap: 2rem;

        &:before {
            content: '';
            background: $primary-color-light;
            border-radius: 100%;
            position: absolute;
            bottom: -48.4375rem;
            left: -25.625rem;
            height: 64rem;
            width: 64rem;
            z-index: -1;
        }

        .card {
            &-body {
                align-items: flex-start;
                padding: 0;
                gap: .5rem;
                border: 0;
                justify-content: center;

                &__title {
                    font: $font-primary-28-b;
                }

                &__info {
                    font: $font-base-23-l;
                }
            }

            &-footer {
                padding: 0;
            }
        }
    }

    &--info{
        border-radius: .375rem;
        padding: 1.5rem;
        gap: 1rem;
        background: $primary-color-white;
        border: $border-secondary-gray-light;
        flex: 1 1 0;
        align-items: stretch;

        .card{
            &-body {
                border-top: none;
                padding: 0;

                &__info{
                    -webkit-line-clamp: unset;
                    line-clamp: unset;
                    display: block;
                }
            }

            &-header{
                padding-top: 0;

                &__thumbnail{
                    width: 100%;
                    &-image{
                        box-shadow: none;
                    }
                }
            }
        }
    }

    &--points{
        padding: 2.5rem 1.5rem 1rem;
        border: none;
        flex: 0 0 auto;
        max-width: 18.375rem;

        .card{

            &-content{
                gap: 1.5rem;
                display: flex;
                align-self: stretch;
                flex-direction: column;
                border-radius: .6875rem;
                border: none;
                padding: 1.5rem;
                background-repeat: no-repeat;
                background-size: cover;
                min-height: 18.375rem;
            }

            &-header{
                padding: 0;
                gap: .25rem;

                &__title{
                    font: $font-primary-42-b;
                    letter-spacing: -.7px;
                    align-self: flex-start;
                }

                &__subtitle{
                    font: $font-primary-18-b;
                    align-self: flex-end;
                }
            }

            &-body{
                border-top: none;
                padding: 0;
                gap: .5rem;
                width: 100%;
                align-items: flex-start;
                justify-content: center;

                &__price{
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    background: $primary-color-white;
                    border-radius: .6875rem;
                    align-self: flex-start;
                    padding: .25rem .75rem;
                    box-shadow: 3px 3px 0 0 rgba(31, 31, 31, 0.25);
                    gap: .5rem;
                    flex-wrap: nowrap;
                    align-items: flex-start;

                    .price{
                        font-family: $base-font-family;
                        font-weight: 700;
                        color: $primary-color-orange;
                        font-size: 1.375rem;
                        line-height: 1.75rem;
                        letter-spacing: -.2px;

                        &--previous{
                            color: $primary-color-black;
                            font-size: 1.25rem;
                            line-height: 1.5rem;

                        }

                        &--lowest {
                            font: $font-base-12-l;
                            display: flex;
                        }
                    }

                    &-regular{
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }
                }

                &__info{
                    font: $font-base-13-l;
                    gap: .125rem;

                    strong{
                        font: $font-base-12;
                    }
                }
            }

            &-footer{
                padding: 0;
                &__actions{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    gap: .75rem;

                }
            }
        }
    }
}