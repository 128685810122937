.data-block {
    &__container {
        @include gap(1rem);
    }
    &-header{
        &__content {
            display: flex;
            justify-content: flex-end;
            gap: .5rem;
        }

        &__btn {
            padding: 0;
            border: none;
            margin-bottom: 0.1875rem;
            background: none;
            align-self: flex-end;
            display: flex;
            gap:.6875rem;
        }
    }
    &__content {
        @include gap(1rem);
    }
    &__title {
        display: flex;
        align-items: center;
        color: $primary-color-black;
        font-family: $first-font-family;
        font-size: 1.75rem;
        font-weight: 700;
        letter-spacing: -0.3px;
        line-height: 2.25rem;
        flex-grow: 1;
        margin: 0;
        gap: .5rem;
        @include media-breakpoint-down($mobile-breakpoint) {
            display: block;
        }
        &-counter {
            font: $font-base-28-l;
        }
    }
    &-line {
        width: 100%;
        height: 0.625rem;
        margin-top: 0.1875rem;
        border: $border-black;
        border-right: none;
        border-bottom: $border-white;
        position: relative;
        background: $primary-color-black;

        &:before, &:after {
            content: "";
            position: absolute;
            top: 0;
            height: 0.5625rem;
            background: $primary-color-white;
        }

        &:before {
            left: 0;
            width: 0.625rem;
            border-top-left-radius: 100%;
            border: $border-white;
            border-right: none;
            border-bottom: none;
        }

        &:after {
            left: 0.4375rem;
            width: calc(100% - 0.375rem);
        }
    }
    &-actions {
        margin: auto;
        display: flex;
    }
    &-radius {
        padding: 1rem;
        gap: 3rem;
        border-radius: 1.375rem;
        border: 1px solid $primary-color-light;
        margin: 0;
        &--black {
            border-color: $primary-color-black;
        }
        &--gray {
            border-color: $secondary-color-gray-light;
        }
        &-group {
            @include gap(1px);
            & > .data-block-radius {
                margin: 0;
                &:not(:last-child) {
                    border-bottom-right-radius: 0;
                    -webkit-border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    -webkit-border-bottom-left-radius: 0;
                }
                &:not(:first-child) {
                    border-top-right-radius: 0;
                    -webkit-border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    -webkit-border-top-left-radius: 0;
                }
            }
        }
        & > & {
            border-radius: .675rem;
        }
        &.content-bg {
            &-gray {
                background-color: $primary-color-light;
            }
            &-white {
                background-color: $primary-color-white;
                input[type="text"] {
                    background-color: $primary-color-light;
                }
            }
        }
        .data-field {
            margin-bottom: .5rem;
            &__label {
                color: $secondary-color-muted;
                font: $font-base-11-l;
                margin-bottom: 0;
            }
            &__value {
                font: $font-base-16;
            }
        }
    }
    form {
        padding-left: 0;
        padding-right: 0;
    }
}


.address {
    padding: 1rem 1.5rem;
    & > * {
        padding: 0;
    }
    &--default {
        &.data-block-radius {
            border-color: $primary-color-black;
        }
    }
    &-block {
        &.data-block-radius-group > .data-block-radius {
            border-color: $primary-color-light;
            background-color: $primary-color-light;
        }
    }
    &-info {
        @include gap(.25rem);
        align-items: flex-start;
        padding: 0;
        margin: auto;
        &__default {
            font-size: 0.75rem;
            line-height: 1rem;
        }
        &__name {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            word-break: break-word;
        }
        &__type {
            font-size: 0.8125rem;
            line-height: 1rem;
            font-weight: 300;
        }
    }
    &-details {
        max-width: 40%;
        text-align: end;
        font-weight: 300;
        word-break: break-word;
    }
    &-form {
        &-actions {
            display: flex;
            align-items: center;
            gap: .5rem;
        }
        &__tabs {
            @include gap(1rem);
        }
    }
}

.payment-cards {
    .card {
        &-header {
            display: flex;
            align-items: flex-start;
            gap: 1.5rem;
            flex: 1 0 0;

            &__content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                justify-content: center;
                flex: 1 0 0;
            }

            &__title {
                font: $font-base-16;
            }

            &__details {
                font: $font-base-12-l;
            }

            &__info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: .25rem;
            }

            &__thumbnail {
                width: 4rem;
                height: 4rem;

                &-image {
                    box-shadow: none;
                    max-width: 4rem;
                    max-height: 4rem;
                }
            }
        }

        &-body {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex: 1 0 0;
        }

        &--small {
            padding: 1rem 1.5rem;
            align-items: center;
            gap: 1.5rem;
            align-self: stretch;
            border: $border-light;
            border-radius: 1.375rem;
            margin: 0;
            background-color: $primary-color-white;
        }
    }
    &-info {
        font: $font-base-16-l;
        margin: 0;
    }

    .expired {
        color: $primary-color-red;
    }
}
