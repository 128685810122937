.tablist {
    margin: 1.25rem;
}

.buttontabs {
    .btn {
        font-size: .875rem;
    }

    .active {
        border: .063rem solid $primary-color-black !important;
    }
}