.np-products-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding: 2.5rem;
    gap: 2.5rem;

    .np-logo-headers {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 1rem;

        .np-logo {
            padding: 0;

            img {
                width: 4rem;
                height: 4rem;
            }
        }

        .np-headers {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            gap: 0.5rem;

            .np-header {
                font-family: $primary-font-family-bold;
                font-style: normal;
                font-weight: 700;
                font-size: 3rem;
                line-height: 3.5rem;
                display: flex;
                align-items: center;
                letter-spacing: -0.036rem;
                color: $primary-color-black;
            }

            .np-subheader {
                font-family: $base-font-family;
                font-style: normal;
                font-weight: 300;
                font-size: 1.125rem;
                line-height: 1.5rem;

                span {
                    color: $primary-color-orange;
                }
            }
        }
    }

    .np-products-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        gap: 0.5rem;
        @include media-breakpoint-down($mobile-breakpoint) {
            flex-wrap: wrap;
            flex-direction: column;
        }

        .np-product-col {

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2.5rem 1.5rem 1rem;
            gap: 0.5rem;
            isolation: isolate;
            border-radius: 0.688rem;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 1;
            position: relative;

            .np-badge {
                display: flex;
                position: absolute;
                width: 5rem;
                height: 5rem;
                right: 0.5rem;
                top: 0.5rem;
                border: 0.25rem solid $primary-color-white;
                border-radius: 3rem;
                flex: none;
                order: 1;
                flex-grow: 0;
                z-index: 1;
                flex-direction: column;
                gap: 0.125rem;

                .np-badge-line-frame {
                    padding: 0 0.563rem;

                    .np-badge-line {
                        border: 1px solid $primary-color-black;
                    }
                }

                .np-badge-up {
                    flex-grow: 1;
                    color: $primary-color-black;
                    font-family: $base-font-family;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.813rem;
                    line-height: 1rem;
                    display: flex;
                    text-align: center;
                    letter-spacing: -0.3px;
                    align-items: end;
                    justify-content: center;
                }

                .np-badge-dn {
                    flex-grow: 1;
                    color: $primary-color-black;
                    font-family: $base-font-family;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.813rem;
                    line-height: 1rem;
                    display: flex;
                    text-align: center;
                    letter-spacing: -0.3px;
                    align-items: start;
                    justify-content: center;
                }

                &-pink {
                    background: $badge-color-pink;

                    .np-badge-dn {
                        font-family: $primary-font-family;
                        font-weight: 500;
                        font-size: 1.25rem;
                        line-height: 1.25rem;
                    }
                }

                &-blue {
                    background: $badge-color-blue;
                }

            }

            .np-product-container {
                background-position: center center;
                background-repeat: no-repeat;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 1.5rem;
                gap: 1.5rem;
                isolation: isolate;
                border-radius: 0.688rem;
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 1;
                z-index: 0;
                width: 15.375rem;
                height: 16.75rem;

                .np-points-row {

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 0;
                    flex: none;
                    order: 0;
                    align-self: stretch;
                    flex-grow: 0;
                    z-index: 0;

                    .np-points {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;
                        padding: 0;
                        gap: 0.25rem;
                        flex: none;
                        order: 0;
                        flex-grow: 0;

                        .np-count {
                            font-family: $primary-font-family-bold;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 2.625rem;
                            line-height: 3rem;
                            letter-spacing: -0.044rem;
                            color: $primary-color-black;
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                        }

                        .np-unit {
                            font-family: $primary-font-family-bold;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 1.125rem;
                            line-height: 2rem;
                            letter-spacing: -0.01rem;
                            color: $primary-color-black;
                            flex: none;
                            order: 1;
                            flex-grow: 0;
                        }
                    }
                }

                .np-price-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 0;
                    gap: 0.5rem;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 1;
                    z-index: 1;

                    .np-price-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 0;
                        gap: 0.5rem;
                        flex: none;
                        order: 0;
                        align-self: stretch;
                        flex-grow: 1;

                        .np-price-label-frame {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            padding: 0;
                            flex: none;
                            order: 0;
                            flex-grow: 0;

                            .np-label-frame {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding: 0;
                                gap: 0.25rem;
                                flex: none;
                                order: 0;
                                flex-grow: 0;

                                .np-old-price {
                                    font-family: $base-font-family;
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 1.125rem;
                                    line-height: 1.5rem;
                                    text-align: center;
                                    text-decoration-line: line-through;
                                    color: $primary-color-black;
                                    flex: none;
                                    order: 0;
                                    flex-grow: 0;
                                }

                                .np-label {
                                    font-family: $base-font-family;
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 0.875rem;
                                    line-height: 1.25rem;
                                    text-align: center;
                                    color: $primary-color-black;
                                    opacity: 0.75;
                                    flex: none;
                                    order: 1;
                                    flex-grow: 0;
                                }
                            }

                            .np-current-price {
                                font-family: $primary-font-family-bold;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 1.75rem;
                                line-height: 2.25rem;
                                text-align: center;
                                letter-spacing: -0.019rem;
                                color: $primary-color-black;
                                flex: none;
                                order: 1;
                                flex-grow: 0;
                            }
                        }

                        .np-promo-price-frame {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            padding: 0;
                            gap: 0.5rem;
                            flex: none;
                            order: 1;
                            flex-grow: 0;

                            .np-promo-price-container {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                padding: 0.25rem 0.75rem;
                                gap: 0.25rem;
                                background: $primary-color-white;
                                box-shadow: 0.188rem 0.188rem 0 rgba(31, 31, 31, 0.25);
                                border-radius: 0.688rem;
                                flex: none;
                                order: 0;
                                flex-grow: 0;

                                .np-price-frame {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    padding: 0;
                                    gap: 0.5rem;
                                    flex: none;
                                    order: 0;
                                    flex-grow: 0;

                                    .np-price-old {
                                        font-family: $primary-font-family-medium;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 1.25rem;
                                        line-height: 1.5rem;
                                        text-align: center;
                                        text-decoration-line: line-through;
                                        color: $primary-color-black;
                                        flex: none;
                                        order: 0;
                                        flex-grow: 0;
                                    }

                                    .np-price-new {
                                        font-family: $primary-font-family-bold;
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 1.375rem;
                                        line-height: 1.75rem;
                                        text-align: center;
                                        letter-spacing: -0.013rem;
                                        color: $primary-color-orange;
                                        flex: none;
                                        order: 1;
                                        flex-grow: 0;
                                    }
                                }
                            }
                        }

                        .np-promo-label {
                            font-family: $base-font-family;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 0.813rem;
                            line-height: 0.875rem;
                            text-align: center;
                            color: $primary-color-black;
                            flex: none;
                            order: 2;
                            flex-grow: 0;

                            span {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }

            .np-buttons-row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0;
                gap: 1.5rem;
                flex: none;
                order: 3;
                align-self: stretch;
                flex-grow: 0;
                z-index: 3;

                button {
                    padding: 0.25rem 1.5rem;
                    height: 2.75rem;
                    flex: none;
                    order: 0;
                    flex-grow: 1;
                }
            }
        }

    }

    .np-footer {
        font-family: $base-font-family;
        font-style: normal;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        color: $primary-color-black;
        flex: none;
        order: 3;
        flex-grow: 0;
    }
}


