.modal {
    [data-bs-dismiss="modal"] {
        cursor: pointer;
    }

    &-content {
        box-shadow: $modal-content-box-shadow-xs;
        padding: 2rem;
        gap: 1rem;
        @include media-breakpoint-up(sm) {
            box-shadow: $modal-content-box-shadow-sm-up;
        }
    }

    &-header, &-body {
        padding: 0;
    }

    &-header {
        &__btn {
            display: flex;
            align-items: center;
        }
    }

    &--bottom & {
        &-dialog {
            width: 100vw;
            max-width: none;
            padding: 0;
            margin: 0;
            height: 100%;
        }

        &-header {
            border-bottom: none;
            max-width: $max-width;
            width: 100%;
            position: absolute;
            align-self: center;
        }

        &-content {
            padding: 1.5rem 3rem;
            margin-top: auto;
            border-radius: 0;
            box-shadow: 0 0 .375rem rgba(0, 0, 0, 0.2);
        }

        &-body {
            max-width: 49.125rem;
            width: 100%;
            margin: auto;
            text-align: left;

            &__separator {
                width: 100%;
                height:1px;
                background-color: $secondary-color-gray-light;
                color: $secondary-color-gray-light;
                margin: 0;
                border: none;
            }
        }
    }

    &--right &{
        &-content{
            margin-bottom: auto;
            border-radius: 0;
            box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.13);
            padding: 0;
            gap: 0;
            height: 100vh;
            background: $primary-color-light;
        }

        &-dialog{
            height: 100%;
            max-width: 20rem;
            margin: 0 0 0 auto;
        }

        &-title{
            font: $font-base-16;
        }

        &-header{
            padding: 1rem;
        }
    }

    &--data-block & {
        &-content {
            padding: 2rem;
            gap: 1rem;
        }

        &-header {
            flex-direction: column;
            align-items: stretch;
            border: 0;
            padding: 0;
        }

        &-message {
            text-align: center;
            font: $font-base-14-l;

            &__warning {
                font-weight: 400;
            }
        }

        &-body {
            padding: 0;
            gap: 1rem;
            display: flex;
            flex-direction: column;
        }

        &-footer {
            padding: 0;
            border: 0;
            justify-content: center;
            gap: .5rem;

            > * {
                margin: 0;
            }
        }

        &-address {
            .data-block-radius {
                flex-direction: column;
                align-items: center;
                border-radius: .6875rem;
                gap: .5rem;
                padding: 1rem;

                &-group {
                    margin: 0;
                }

                .address-info {
                    align-items: center;
                    flex-direction: column-reverse;

                    &__name {
                        font: $font-base-16;
                    }
                }

                .address-details {
                    text-align: center;
                    max-width: 100%;
                    font: $font-base-14-l;
                }
            }
        }

        &__form {
            .data-block-radius {
                --bs-gutter-x: 1rem;
                border-radius: .375rem;
                padding: 1.5rem 1rem;

                .form {
                    &-row {
                        --bs-gutter-y: 1rem;
                    }
                    &-footer {
                        margin-top: 1rem;
                    }
                }
            }
        }

        &-delete-card {
            width: 100%;

            &__notice {
                font: $font-base-14-l;
                text-align: center;
                margin: 0;

                &--highlighted {
                    font: $font-base-14;
                }
            }

            &__card-info {
                background: $primary-color-light;
                border-radius: .375rem;
                padding: 1rem;
            }
        }
    }

    &--scrollable{

        .ps__thumb-y{
            right: 0;
        }

        .modal-body{
            overflow: hidden;
            gap:0;
            position:relative;
            max-height: 24rem;
            padding-right: 1.25rem;

            .btn-check-box{
                padding: 1rem;
                &:not(.last){
                    border-bottom: 1px solid $secondary-color-gray-light;
                }
            }
        }
    }


    .saved-card__info{
        &--brand{
            margin-bottom: 0;
            font: $font-base-18;
            font-weight: 600;
            line-height: 1.375rem;
        }
    }
}

.alert{
    &.alert--bottom{
        box-shadow: 0.188rem 0.188rem 0 $secondary-color-gray-light;
        border-radius: 0.688rem;
        margin: auto;
        max-width: 76rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $primary-color-light;
        padding: 0.938rem 1.938rem 0.938rem 1.938rem;
        align-items: center;
        z-index: 50;
        .alert-image{
            padding-right: 0.75rem;
        }
        .alert-body{
            align-items: center;
            &__text{
                p{
                    margin-bottom: 0;
                }
            }
            &__actions-item{
                display: flex;
                .btn-link{
                    margin: 0 1.5rem;
                }
            }
        }
        @include media-breakpoint-down(md) {
            .alert-body{
                flex-direction: column;
                .alert-body__actions-item{
                    margin-top: 1.5rem;
                }
            }
        }
    }
}

.marketing-modal {
    .alert {
        max-width: 43.75rem;
        display: none;
        flex-direction: row;
        align-items: center;
        padding: 1rem 2rem;
        gap: 1rem;

        &-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 1.5rem;

            &__actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0;
                gap: 1.5rem;
            }
        }

        @include media-breakpoint-up($mobile-breakpoint) {
            width: 45%;
            transform: translateX(2.5%);
            -webkit-transform: translateX(2.5%);
        }

        &__button--reject {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: .5rem;
            gap: .5rem;
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

#downloadProductModal {
    .modal-body {
        display: flex;
        gap: 1rem;
        flex-direction: column;

        &__download-product {
            &--details {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 1rem;
                gap: 1rem;
                background: $primary-color-light;
                border-radius: .375rem;

                .card-body__price {
                    display: none;
                }
            }
        }
    }
}


#emailChangeModal{

    .current-email{
        font: $font-base-16-l;
    }

    .form-floating{
        &.first{
            margin-bottom: 1rem;
        }
    }
}

#avatarChange {
    form {
        width: 26.5625rem;

        .modal__form {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1.5rem;
            gap: 1rem;

            border: $border-secondary-gray;
            border-radius: 0.375rem;

            #avatar_change_update {
                &__info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0;
                    gap: 0.25rem;

                    font-style: normal;
                    text-align: center;
                    color: $button-color;
                    font: $font-base-16-l;

                    strong {
                        font-weight: 400;
                    }
                }

                &__errors {
                    font-style: normal;
                    font-weight: 400;
                    font: $font-base-11;
                    color: $primary-color-red;
                    gap: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &:empty{
                        display: none;
                    }
                }

                &__buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    gap: 0.5rem;

                }
            }
        }
    }
}
