@import 'nouislider/dist/nouislider.css';

.range-slider {
    background: $secondary-color-gray-light;
    border: none;
    box-shadow: none;
    border-radius: .25rem;
    height: .25rem;

    .noUi-target {
        border: none;
        box-shadow: none;
    }

    .noUi-connect {
        background: $primary-color-black;
    }

    .noUi-handle {
        border-radius: 0.5rem;
        height: 1rem;
        right: -0.5rem;
        top: -0.375rem;
        width: 1rem;
        -webkit-box-shadow: 0.1875rem 0 0 0 $primary-color-black;
        -moz-box-shadow: 0.1875rem 0 0 0 $primary-color-black;
        box-shadow: 0.1875rem 0 0 0 $primary-color-black;
        border: $border-black;
    }

    .noUi-handle:after,
    .noUi-handle:before {
        content: none;
    }
}