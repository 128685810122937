form {
    .input-wrapper {
        position: relative;
        justify-content: center;
        width: 100%;
        margin-bottom: 0.625rem;

        input:active ~ .label,
        input:focus ~ .label,
        input:not(:placeholder-shown) ~ .label {
            top: 0.25rem;
            font-size: 0.6875rem;
            transition: all .2s ease
        }

        input {
            padding: 1.25rem 2.5rem 1rem 0.875rem;
            border: 0;
            outline: none;
            color: $primary-color-black;
            font-size: 1rem;
            border-radius: 0.6875rem;
            width: 100%;
        }

        .label {
            position: absolute;
            top: 1.125rem;
            left: 0.875rem;
            font-size: 1rem;
            font-weight: 300;
            font-family: $base-font-family;
            margin: 0;
            color: $primary-color-black;
            transition: all .2s ease;
            pointer-events: none;
        }

        .label-regular {
            position: relative;
            top: -4.375rem;
            left: auto;
            margin-bottom: 0.3125rem;
            font-size: 1rem
        }

        .indicator {
            position: absolute;
            right: 0.875rem;
            top: 1.75rem;
            display: block;
            width: 0.375rem;
            height: 0.375rem;
            border-radius: 100%;
            background-color: #b1b1b1;
        }
    }

    &.was-validated {
        :invalid {
            & ~ .invalid-hidden {
                display: none;
            }

            & ~ .invalid-visible {
                display: block;
            }

            & ~ .indicator {
                background-color: #ec5d2b;
            }
        }

        :valid {
            & ~ .valid-hidden {
                display: none;
            }

            & ~ .valid-visible {
                display: block;
            }

            & ~ .indicator {
                background-color: #29b473;
            }
        }
    }

    ::-webkit-scrollbar {
        width: .4rem;

        &-track {
            border-radius: .6rem;

            &-piece {
                background: transparent;

                &:start {
                    margin-top: .3rem;
                }

                &:end {
                    margin-bottom: .3rem;
                }
            }
        }

        &-thumb {
            background: $secondary-color-gray;
            border-radius: .6rem;

            &:hover {
                background: $secondary-color-gray-dark;
            }
        }
    }
}

.form {
    &-row {
        --bs-gutter-x: 1.25rem;
        --bs-gutter-y: 1.25rem;
    }

    &-floating {
        > .form-control {
            font: $font-base-16;
            padding: 0 $input-height-inner 0 1rem;
            border-radius: .5rem;

            &:not(:placeholder-shown), &:focus {
                padding-top: 1.375rem;

                ~ label {
                    opacity: 1;
                    font: $font-base-11-l;
                    transform: translateY(-0.4rem);
                }
            }

            &:disabled {
                background: $input-disabled-bg;
            }
        }

        > label {
            font: $font-base-16-l;
            left: 1rem;
            padding: 1rem calc(var(--bs-gutter-x, 0rem) * .5);
        }

        .invalid-feedback {
            position: absolute;
            padding-left: 1rem;
        }
    }

    &-footer {
        margin-top: 3rem;
    }

    $multi-range-height: 1px;

    &-multi-range {
        -webkit-appearance: none;
        position: absolute;
        width: calc(100% - 1.125rem);
        pointer-events: none;
        background-color: transparent;
        outline: none;
        border: none;
        margin: 0;
        padding: 0;

        &-max {
            right: 0;
        }

        &-min {
            left: 0;
        }

        &-max::-moz-range-track {
            visibility: hidden;
        }

        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            background-color: $primary-color-black;
            height: $multi-range-height;
            border: none;
            padding: 0;
        }

        &::-moz-range-track {
            -moz-appearance: none;
            background-color: $primary-color-black;
            height: $multi-range-height;
            z-index: -1;
            border: none;
            padding: 0;
        }

        &::-ms-track {
            appearance: none;
            background-color: $primary-color-black;
            height: $multi-range-height;
            border: none;
            padding: 0;
        }

        &::-webkit-slider-thumb,
        &:focus::-webkit-slider-thumb {
            -webkit-appearance: none;
            pointer-events: auto;
            height: 1em;
            width: 1em;
            cursor: pointer;
            background: $primary-color-light;
            border: 1px solid $primary-color-black;
            box-shadow: 0.1875rem 0 $primary-color-black;
            border-radius: 50%;
            margin-top: -0.375rem;
            z-index: 1;
            position: relative;
        }

        &::-moz-range-thumb,
        &:focus::-moz-range-thumb {
            -moz-appearance: none;
            pointer-events: auto;
            height: 1em;
            width: 1em;
            cursor: pointer;
            background: $primary-color-light;
            border: 1px solid $primary-color-black;
            box-shadow: 0.1875rem 0 $primary-color-black;
            border-radius: 50%;
            z-index: 100;
        }

        &::-ms-thumb {
            appearance: none;
            pointer-events: auto;
            height: 1em;
            width: 1em;
            cursor: pointer;
            background: rgba(255, 0, 0, 0.6);
            border: 0;
            border-radius: 50%;
        }
    }

    .textarea-div {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0.063rem;
            left: 0.063rem;
            width: calc(100% - 1.6rem);
            height: 2rem;
            border-radius: 1.25rem;
            background-color: $primary-color-white;
        }

        .label-textarea {
            height: 7rem !important;
            padding-top: 2rem;
            background-position: right 1rem top 1.5rem !important;
        }
    }
}

.form-control {
    font: $font-base-16;
    padding-right: add(2rem, $input-height-inner-half);
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6'><circle fill='#{$form-feedback-icon-neutral-color}' cx='3' cy='3' r='3'/></svg>"));
    background-repeat: no-repeat;
    outline: none;

    &, &.is-invalid, &.is-valid {
        &, .was-validated & {
            background-size: $input-height-inner-half $input-height-inner-half;
            background-position: right 1rem center;
        }
    }

    &:not([required="required"]) {
        background-image: unset !important;
    }
}

.form-legend {
    display: flex;
    align-items: center;
    font: $font-base-14-l;
    height: 100%;
    padding-left: add($input-height-inner-half, .5rem);
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6'><circle fill='#{$form-feedback-icon-neutral-color}' cx='3' cy='3' r='3'/></svg>"));
    background-repeat: no-repeat;
    background-position: left center;
    background-size: $input-height-inner-half $input-height-inner-half;
}

.form-info {
    font: $font-base-14-l;
    color: $primary-color-black;
    margin-bottom: 1rem;
}


.needs-validation {
    .form-check-input {
        &:valid {
            color: $primary-color-black;
            border-color: $primary-color-black;

            & ~ .form-check-label {
                color: $primary-color-black;
            }
        }

        &:focus {
            box-shadow: none;
        }

        &[type="checkbox"] {
            &:focus {
                box-shadow: 0 0 0 .25rem rgba(123, 123, 123, 0.24);
            }

            &:valid:checked {
                background-color: $primary-color-black;
            }
        }

        &.is-valid[type="radio"]:checked, &[type="radio"]:valid:checked {
            background-color: $primary-color-white;
        }
    }
}

.invalid-feedback {
    font: $font-base-11;
}

.form-check {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 1.625rem;

    &-label {
        margin-bottom: 0;

        & > p {
            margin-bottom: 0;
        }
    }

    &-input {
        height: 1.25em;
        width: 1.25em;
        border-radius: .25rem;
        border-color: $primary-color-black;

        &:not(:checked):hover, &:not(:checked):not([type="checkbox"]):focus {
            border-color: $primary-color-orange;
        }

        &[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &:not(:checked):hover {
                border-color: $primary-color-orange;
            }
        }

        &:focus {
            box-shadow: none;
        }
    }

    &-required {
        color: $primary-color-red;
    }


    .form-check-input {
        margin-left: -1.625rem;
    }
}

.form-radio {
    background-color: $primary-color-white;

    &:checked {
        background-color: $primary-color-white;
        border-color: $primary-color-black;
    }

    &:hover:not(:checked) {
        border-color: $primary-color-orange;
    }
}

.form-field {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}

.#{$selectize} {
    &-control {
        height: 100%;
    }

    &-input {
        height: 100%;
        min-height: auto;

        .selectize-control.single & {
            display: flex;
            padding-right: calc(#{$select-padding-arrow-x} * 2);

            &.input-active {
                display: flex;
            }

            &:read-only {
                cursor: pointer;
            }

            &::after {
                @extend .icon-arrow-down;
                background: var(--icon-image) no-repeat right 1rem center;
                background-size: $select-arrow-background-size;
                padding: $select-padding-arrow-y $select-padding-arrow-x;
                top: calc(50% - #{$select-padding-arrow-y});
            }
        }

        &.focus {
            &.btn {
                border-color: var(--focus-border-color);
                box-shadow: var(--focus-shadow);
            }
        }

        &.btn-secondary {
            > .selectize-input__search {
                height: 100%;
                border-right: $border-secondary-gray !important;
                position: relative !important;
                opacity: 1 !important;
                width: 0 !important;
                left: 0 !important;
            }
        }
    }

    &-dropdown {
        margin-top: calc(#{$select-dropdown-arrow-size} + .25rem);
        border: 0;
        border-radius: $select-dropdown-radius;
        box-shadow: $select-dropdown-box-shadow;
        text-align: left;

        &-content {
            background: inherit;
            border-radius: inherit;
            margin: $select-margin-dropdown-content-y $select-margin-dropdown-content-x;

            &::before {
                content: '';
                position: absolute;
                background: inherit;
                top: -#{$select-dropdown-arrow-size};
                left: calc(50% - #{$select-dropdown-arrow-size});
                padding: $select-dropdown-arrow-size;
                transform: rotate(45deg) scale(70.7%);
                z-index: -1;
            }

            > .ps__rail-y {
                top: $select-margin-dropdown-content-y !important;
                right: $select-margin-dropdown-content-x !important;

                > .ps__thumb-y {
                    right: 0;
                    top: 0;
                }
            }

            > .option {
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 1rem;
                font-weight: 300;

                &.active {
                    font-weight: 400;
                    border-radius: .375rem;
                }
            }
        }
    }
}

.form-select {
    line-height: 1.5rem;
    color: $primary-color-black;
    border-radius: .75rem;
    border: $border-secondary-gray;
    padding: 1rem 3.5rem 1rem 1.5rem;
    background: url('../../images/icons/chevron_down.svg') no-repeat right 1.5rem center $primary-color-white;
    background-size: 2rem;


    &.selectize-control {
        padding: 0;
        border-radius: .75rem;

        .selectize-input {
            background-color: transparent;
            border: none;
            border-radius: .75rem;
            overflow: hidden;
            padding: 1rem 3.5rem 1rem 1.5rem;

            &::after {
                /*background: url('../../images/icons/chevron_down.svg') no-repeat right 1.5rem center transparent;*/
                background: transparent;
                background-size: 2rem;
                border-color: transparent !important;
            }
        }

        .item {
            line-height: 1.5rem;
            font-size: 1rem;
            margin-left: 0 !important;
        }
    }

    &.selectize-dropdown {
        margin: 0;
        padding: 1.5rem 1rem;
        top: 4.75rem !important;

        &.sales-variant-select {
            width: 27.5rem !important;
            left: calc((27.5rem - 19.125rem) / 2 * -1) !important;

            @include media-breakpoint-down($mobile-breakpoint) {
                width: 100% !important;
                left: 0 !important;
            }
        }


        &.release-select {
            width: 26.25rem !important;
            left: calc((26.25rem - 19.125rem) / 2 * -1) !important;

            @include media-breakpoint-down($mobile-breakpoint) {
                width: 100% !important;
                left: 0 !important;
            }
        }

        .btn-check-box.selected:hover {
            .form-check-input {
                border-color: $primary-color-black;
            }
        }

        &::after {
            width: 100%;
            height: 1px;
            background: $primary-color-white;
            content: " ";
            display: block;
            position: absolute;
            bottom: 1.5rem;
            left: 0;
            z-index: 100;
        }
    }

    .selectize-dropdown-content {
        margin: 0;
        padding: 0;
        max-height: 18.75rem;
        border-radius: 0;
    }


    .form-select {
        border: none;
        background: $primary-color-white;

        &:focus {
            box-shadow: none;

        }
    }

    &:focus {
        box-shadow: 0 0 0 .25rem rgba(123, 123, 123, 0.25);
    }

    .btn-check-box {
        padding: 1rem;


        .price {
            font-weight: 700;

            &--previous {
                font-weight: 300;
            }

            &--lowest {
                font: $font-base-12-l;
                display: block;
                width: 100%;
                text-align: right;
            }
        }

        &:hover {
            .form-check-input {
                border-color: $primary-color-orange;
            }
        }

        &.selected {
            background: $primary-color-light;

            .btn-check-box__title {
                font-weight: 600;
            }

            &.active {
                background: $primary-color-light;
            }
        }

        &:not(.selected) {
            .btn-check-box__title {
                font-weight: 400;
            }
        }

        .price {
            font-size: .875rem !important;

            &.price--previous {
                line-height: 1rem !important;
            }
        }
    }
}

.line-under-form {
    .line-gray {
        border-top: .031rem solid $secondary-color-gray-dark;
        width: 9.188rem;
    }

    .text-between-line {
        color: $secondary-color-gray-dark;
        font-size: .75rem;
    }
}

.password {

    &-wrapper {
        display: flex;
        gap: 2rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding-bottom: 1.5rem;

        .form-floating{
            max-width: 24.75rem;
        }

        > * {
            margin: 0;
        }
    }

    &-validation-box {
        border-radius: 1.375rem;
        padding: .75rem 1.5rem;
        max-width: 23.25rem;
        align-self: center;
        display: flex;
        justify-content: space-evenly;
        box-shadow: .1875rem .1875rem 0 $secondary-color-gray-light;
        border: 1px solid $secondary-color-gray;

        .dot {
            display: inline-block;
            width: .375rem;
            height: .375rem;
            border-radius: 50%;
            background: $secondary-color-gray-dark;
        }

        .condition {
            text-align: center;
            color: $primary-color-black;
            font-size: .8125rem;
            line-height: 1rem;
            font-weight: 300;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5rem;

            &.is-valid {
                font-weight: 400;

                .dot {
                    background: $secondary-color-green;
                }
            }

            &.is-invalid {
                color: $primary-color-red;

                .dot {
                    background: $primary-color-red;
                }
            }
        }
    }
}
.account-delete {
    &--warning {
        text-align: center;
        color: $primary-color-black;
        font: $font-base-14-l;
        font-style: normal;

        strong {
            color: $primary-color-black;
            font: $font-base-14-l;
            font-style: normal;
            font-weight: 400;
        }
    }

    &--form-info {

        color: $primary-color-black;
        font: $font-base-14-l;
        font-style: normal;

        .data-block-radius:has(&) {
            gap: 1rem;
        }
    }
}
