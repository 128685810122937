.quick-purchase__modal {
    .modal{
        &-body {
            max-width: 62.125rem;
            width: 100%;
        }

        &-content{
            @include media-breakpoint-down($mobile-breakpoint) {
                padding: 2.5rem 1.25rem 3rem;
            }
        }

        &-header{
            position: relative;
        }
    }

    .product{
        width: 100%;
        padding-bottom: 1.5rem;
    }

    .quick-purchase {
        &-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 3rem;

            .modal-body__title {
                font: $font-primary-18-b;
                color: $primary-color-black;
            }

            .modal-body-left {
                padding: .5rem;
                max-width: 15rem;
                border-right: $border-secondary-gray-light;
                width: 100%;
            }

            .modal-body-right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 0;
                gap: 1.5rem;
                max-width: 44.125rem;
                width: 100%;
            }

            .price {
                font-family: $primary-font-family-medium;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.5rem;

                &--previous {
                    font: $font-base-18-l;
                    text-decoration: line-through;
                    color: $secondary-color-gray-dark;
                }

                &--lowest {
                    font: $font-base-12-l;
                    display: flex;
                }

                &-points {
                    display: flex;
                    justify-content: center;
                    font: $font-base-14-l;
                    @include media-breakpoint-down($mobile-breakpoint) {
                        justify-content: flex-start;
                    }
                }
            }

            .agreements {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                gap: .5rem;
                width: 100%;

                .form-check, label {
                    margin: 0;
                }
            }

            .button-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0;
                gap: 1.5rem;

                @include media-breakpoint-down($mobile-breakpoint) {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                &__description {
                    font: $font-base-14-l;
                }

                .google-pay {
                    background-image: url("../../images/payu/google_pay.png");
                    background-size: 10.625rem;
                    width: 17.5rem;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .apple-pay {
                    background-image: url("../../images/payu/apple_pay.png");
                    background-size: 11.625rem;
                    width: 17.5rem;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }

        &-img-fluid {
            max-width: 10rem;
            max-height: 13.75rem;
        }
    }

    .invoice-data {
        width: 100%;
        gap: .5rem;

        &-wrapper {
            width: 100%;

            .invoice-address {
                .address {
                    justify-content: space-between;
                    padding: .75rem 1.5rem;

                    .data-block-actions {
                        display: block;
                    }
                }

                .data-block-radius {
                    gap: 1rem;
                    align-items: center;
                    border-radius: .75rem;
                    flex-wrap: nowrap;

                    &:hover {
                        border-color: $secondary-color-gray-light;
                    }

                    .address-info, .address-details, .data-block-actions {
                        //text-overflow: ellipsis;
                        overflow: hidden;

                    }

                    .address-info-content{
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: start;
                    }

                    .address-info__name {
                        font-size: .875rem;
                    }

                    .address-info__type {
                        font-size: .75rem;
                        line-height: 1rem;
                    }

                    .btn-link {
                        font-size: .8125rem;
                        line-height: .875rem;
                        font-weight: 400;
                        color: $primary-color-black;
                        white-space: nowrap;
                        padding: 0;
                        border-radius: 0;
                        border-bottom: 1px solid $primary-color-black;
                    }

                    .address-details {
                        font-size: .75rem;
                        text-align: start;
                        max-width: 100%;
                    }
                }

                &.no-addresses--btn {
                    cursor: pointer;

                    .address {
                        min-height: auto;

                        &.addresses-block-header__btn {
                            .col-12 {
                                display: flex;
                                justify-content: flex-start;
                            }
                        }

                        .data-block-actions {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .payment {
        &-method {
            &-container {
                gap: 0.5rem;
            }

            &-box {
                width: 10.65625rem;
                height: 7.25rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 1rem;
                gap: .25rem;

                .pay-icon-container {
                    margin: 0 auto;
                }

                &.has-card {
                    height: 8.5rem;
                }

                p {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &__header {
                display: flex;
                flex-direction: row;
                padding: 0;
                gap: .3125rem;
                justify-content: space-between;
                align-items: center;
            }

            &__title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                gap: 0;
            }

            &__titledescription {
                font: $font-base-12-l;
            }

            &__paybytransferlink {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: .25rem 0 0 0;
                gap: .5rem;

                a {
                    font: $font-base-14-l;
                    text-decoration: none;
                    color: $primary-color-black;

                    &:hover {
                        color: $primary-color-orange;

                        hr {
                            color: $primary-color-orange;
                            background-color: $primary-color-orange;
                        }
                    }
                }

                hr {
                    height: 1px;
                    width: 100%;
                    margin-top: .5rem;
                    margin-bottom: 0;
                    padding: 0;
                    color: $primary-color-black;
                    background-color: $primary-color-black;
                    border: none;
                }
            }
        }
    }

    .form-floating.blik_code_container {
        .blik_code_input {
            width: 10rem;
        }

        & > label {
            left: calc(1rem - 0.625rem);
        }
    }

    .product__formats{
        width: 100%;

        .product-sales-models{
            width: 100%;
            .price{
                font-weight: 500;
                font-size: 1.1875rem;
                line-height: 1.5rem;
                &.price--previous{
                    font: 300 0.875rem/1.25rem "Source Sans Pro", sans-serif;
                }
            }
        }
    }

    .form-select.selectize-dropdown{
        &.sales-variant-select{
            width: calc(100% + 5rem) !important;
            left: -2.5rem !important;
        }
        &.release-select {
            width: calc(100% + 2rem) !important;
            left: -1rem !important;
        }
    }
}
