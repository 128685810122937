.list {
    @include gap(1rem);

    &-header {
        &__title {
            font: $font-primary-28-b;
            letter-spacing: -0.3px;
            margin: 0;

            &--count {
                font: $font-base-28-l;
                margin-left: .5rem;
            }
        }
    }

    &__item {
        padding: 0;

        & + & {
            border-top: $border-secondary-gray-light;
        }
    }
}

.grid-list {
    padding-left: .75rem;
    padding-right: .75rem;

    &__item {
        position: relative;

        .item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 3rem;

            &__thumbnail {
                position: relative;
            }
        }

        &:not(:hover) {
            @include media-breakpoint-up($mobile-breakpoint) {
                .item-actions {
                    opacity: 0;
                    transition: opacity .2s ease;
                }
            }
        }

        .item-actions {
            position: absolute;
            top: 0;
            right: -2.8125rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: auto;
            opacity: 1;
            gap: .25rem;
            transition: opacity .2s ease;

            &__btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem;
                width: 2.5rem;
                height: 2.5rem;
                border: none;
                box-shadow: none;
                background-color: transparent;
            }
        }
    }
}

.products-list {
    &__item {

        &--big {
            .product {
                &-details {
                    /*max-width: 11.625rem;*/
                    @include media-breakpoint-up($mobile-breakpoint) {
                        text-align: left !important;
                    }
                }

                &__name {
                    font-size: 2.25rem!important;
                    line-height: 2.25rem!important;
                    padding-bottom: .25rem;
                    font-family: $primary-font-family-bold;
                    font-weight: 700;
                    padding-top: .5rem;
                }

                &__authors {
                    font-size: 1.25rem!important;
                }
            }
        }

        &:not(.products-list__item--big) {
            .item {
                justify-content: flex-end;
            }
        }

        .product {
            &-details {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: .25rem;
                font-family: $base-font-family;
            }

            .item {
                margin-bottom: 0;
                height: 100%;
                padding: .5rem;
                gap: .75rem;
            }

            &__cover {
                box-shadow: -1.125rem 0px 2.25rem -1.125rem rgba(0, 0, 0, 0.16), -1.125rem 0px 1.125rem -1.125rem rgba(0, 0, 0, 0.16);

                img {
                    max-height: 13.75rem;
                    max-width: 10rem;
                }
            }

            &__type {
                font-size: .75rem;
                line-height: 1rem;
                font-weight: 300;
                color: $secondary-color-gray-dark;
            }

            &__name {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $primary-color-black;
            }

            &__authors {
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 300;
                color: $primary-color-black;
            }
        }


        &--big {
            .product {
                .item {
                    gap: 1.5rem;

                    @include media-breakpoint-up($mobile-breakpoint) {
                        flex-direction: row;
                        justify-content: center;
                    }
                }
            }

            .product__cover {
                img {
                    max-height: 17rem;
                    max-width: 11.625rem;
                }
            }

            .item-actions {
                left: calc(100% + 1.5rem);
                right: unset;
                flex-direction: row;
            }
        }
    }
}

.orders-list {
    padding-left: 0;
    padding-right: 0;

    .accordion {
        &--table {
            &, & .order {
                border: 0;
            }
        }
    }

    .orders {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        align-items: stretch;
    }

    .orders__header {
        border-bottom: $border-secondary-gray-light;
        display: flex;
        flex-direction: row;

        [class^="col-"] {
            font-size: .75rem;
            color: $secondary-color-muted;
            padding: .75rem 2rem;
        }
    }

    .order {
        &-header {
            display: flex;
            flex-direction: row;
            height: 4rem;
            align-items: stretch;

            &__cell {
                padding: 0 2rem;
                display: flex;
                align-items: center;
                font: $font-base-16-l;
                border-right: 1px solid $primary-color-white;

                &--group {
                    padding: 0;
                    border: 0;
                }

                &--status, &--action {
                    border-right: 0;
                }

                &--action {
                    justify-content: flex-end;

                    &-mobile {
                        display: none;
                    }
                }

                &--status {
                    &.color-orange {

                    }
                }

                &--price {
                    &-mobile {
                        display: none;
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: row;
            align-items: stretch;
        }

        &-footer {
            display: flex;
            justify-content: flex-end;

            &--revert-order {
                flex-direction: row-reverse;
                justify-content: flex-start;
            }

            &__cell {
                display: flex;
                align-items: center;
                padding: 1rem 2rem;

                &--summary {
                    font: $font-base-16-l;

                    span {
                        font: $font-base-16;
                        margin-left: .25rem;
                    }
                }
            }
        }

        &.accordion-item--expanded {
            .accordion {

                &-header {
                    border-radius: 1.5rem 1.5rem 0 0;
                    background: $secondary-color-gray-light;
                }

                &-collapse {
                    border-radius: 0 0 1.5rem 1.5rem;
                    background: $primary-color-light;
                }

                &-body {
                    a {
                        text-decoration: none;
                    }

                    .card--table {
                        border-bottom: 1px solid $primary-color-white;
                    }

                    .badge--light {
                        background: $primary-color-white;
                    }
                }
            }


        }
    }

    .orders__details {
        .accordion {
            &-body {
                padding: 0;

                .card {
                    &-header {
                        padding: 1rem 2rem;
                        align-items: center;
                        gap: 1rem;
                        align-self: stretch;

                        &__details {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            flex: 1 0 0;
                        }
                    }

                    &-body {
                        flex-direction: row;

                        &__content {
                            width: 50%;
                            align-items: center;

                            > * {
                                flex: 1;
                            }
                        }

                        &__info {
                            flex-direction: unset;
                        }

                        &__price {
                            padding: 1rem 2rem;
                            justify-content: flex-end;
                            display: flex;
                            flex-direction: column;
                            gap: .25rem;

                            .price-old-new {
                                display: flex;
                                justify-content: flex-start;
                                flex-direction: row-reverse;
                                gap: .25rem;
                            }

                            .price-points {
                                flex: 0 0 0;
                            }
                        }
                    }
                }

            }
        }
    }



    .awaiting-payment-status {
        color: $primary-color-orange;
        font-weight: 400;
        text-decoration: none;
    }


    @include media-breakpoint-down($mobile-breakpoint) {
        .orders {
            gap: 1rem;

            .order {
                border-radius: 1.375rem;
                border: 1px solid $secondary-color-gray-light;

                &-header {
                    flex-direction: column;
                    height: auto;
                    align-items: flex-start;
                    gap: 1px;
                    align-self: stretch;

                    &__cell {
                        padding: 0;
                        border-right: 0;

                        &--group {
                            flex-direction: column;
                            align-items: stretch;
                        }

                        &--id {
                            font: $font-base-16;
                            order: 2;
                        }

                        &--date {
                            order: 1;
                        }

                        &--price {
                            justify-content: flex-end;
                        }
                    }
                }

                &__content {
                    border-bottom: 1px solid $secondary-color-gray-light;
                    align-items: center;
                    padding: 1rem 1.5rem;
                    gap: 1.25rem;

                    &:last-child {
                        border-bottom: 0;
                    }
                }

                &.accordion-item--expanded {
                    border: 0;

                    .order {
                        &-header {
                            &__cell {
                                &--price {
                                    display: none;

                                    &-mobile {
                                        display: flex;
                                    }
                                }

                                &--action {
                                    display: none;

                                    &-mobile {
                                        display: flex;
                                    }
                                }
                            }
                        }

                        &__content {
                            border-color: $primary-color-white;
                            padding: 1rem 2rem;

                            &:first-child {
                                min-height: 6.25rem;
                            }
                        }

                        &-footer {
                            flex-direction: column;

                            &__cell {
                                justify-content: flex-end;
                            }
                        }
                    }

                    .accordion {
                        &-body {
                            .card--table {
                                flex-direction: column;
                            }
                        }
                    }
                }
            }

            .card {
                &-body {
                    &__details {
                        border-right: 0;
                    }
                }
            }
        }
    }
}

.authors-list__item{
    .card-header__thumbnail-image{
        box-shadow: none;
        border-radius: 100%;
    }
}

.author{
    text-decoration: none;
}

.orders__empty {
    text-align: center;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    &--title {
        font: $font-primary-28-b;
        margin: 0;
    }

    &--info{
        font: $font-base-16-l;
        margin: 0;
    }

    &-btns{
        display: flex;
        justify-content: center;
        gap: .5rem;
    }


}