.product {
    height: 100%;
    display: block;
    text-decoration: none;

    &-sales-models {
        border-radius: 1rem;
        width: 23.3125rem;
        border: 1px solid $secondary-color-gray;
        @include media-breakpoint-down($mobile-breakpoint) {
            width:100%;
        }
        .btn-check-box.active {
            background: inherit;
        }

        .sales-model {
            display: grid;
            grid-template-columns: 1fr 5fr 2fr;
            grid-template-rows: 4.5rem auto;
            grid-column-gap: .5rem;
            align-items: center;
            border-bottom: 1px solid $secondary-color-gray;

            &:last-child {
                border: none;
            }

            &__radio {
                grid-area: 1 / 1 / 2 / 2;
                display: flex;
                justify-content: end;
                -webkit-justify-content: flex-end;
            }

            &__info {
                grid-area: 1 / 2 / 2 / 3;
                &--name {
                    font-weight: 600;
                    font-size: .875rem;
                }

                &--release {
                    font-size: .75rem;
                    font-weight: 300;
                }

                strong {
                    font-weight: 400;
                }
            }

            &__price {
                display: flex;
                flex-direction: column;
                grid-area: 1 / 3 / 2 / 4;
                text-align: end;
                padding-right: 2rem;
                
                .price--previous {
                    color: $button-color-muted;
                    text-decoration: line-through;
                    font-weight: 300;
                    font-size: .875rem;
                    order: 1;
                }

                .price {
                    font-size: 1.1875rem;
                    font-family: $primary-font-family-medium;
                    order: 2;
                }
            }

            &__selectors {
                display: flex;
                flex-direction: column;
                grid-area: 2 / 1 / 3 / 4;
                padding: 0;
                gap: 0.5rem;
            }
        }

        .selectize-input {
            height: 3.5rem;
            background-color: $primary-color-white;

            & .item {
                display: flex;
                align-items: center;
                margin-left: .5rem;
                font-weight: 400;
            }
        }

        .price-points{
            display: none;
        }

        .btn-group-vertical{
            .btn-check-box{
                padding: 1rem 2rem;
            }
        }
    }

    &-info {
        &__header {
            &-row {
                --bs-gutter-x: 2rem;
            }
            &, &-column {
                display: flex;
                flex-direction: column;
                gap: .5rem
            }
        }

        &__contributors {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }

        &__title {
            font: $font-primary-42-b;
            letter-spacing: -0.7px;
        }

        &__subtitle {
            font: $font-base-27-l;
        }
    }
}

.details {
    padding: 0;

    &-header {
        border-right: 1px solid $secondary-color-gray-dark;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        gap: .5rem;
        @include media-breakpoint-down($mobile-breakpoint) {
            align-items: center;
            justify-content: center;
        }
        &__title {
            font-family: $primary-font-family-bold;
            font-size: 1.375rem;
            line-height: 1.75rem;
            text-align: right;
            margin: 0;
            @include media-breakpoint-down($mobile-breakpoint) {
                text-align: left;
            }

        }

        &__thumbnail {
            border-radius: 100%;
        }

        &__info {
            font-size: 1.125rem;
            line-height: 1.5rem;
            text-decoration: none;
        }
    }

    &-content {
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: .5rem;
        font-family: $base-font-family;
        @include media-breakpoint-down($mobile-breakpoint) {
            align-items: center;
            justify-content: center;
        }
        &:not(:last-child) {
            border-right: 1px solid $secondary-color-gray-dark;
        }

        &__item {
            margin: 0;
            font-size: .875rem;
            line-height: 1.25rem;
            font-weight: 300;
            font-family: $base-font-family;
            @include media-breakpoint-down($mobile-breakpoint) {
                text-align: center;
            }
        }

        &__description {
            font-weight: 300;
            font-size: 1rem;
            line-height: 1.5rem;
            font-family: $base-font-family;
        }
    }
}

.formats {
    font-size: .875rem;
    line-height: 1.25rem;
    color: $primary-color-black;
    font-weight: 400;
    gap: .5rem;

    &__item {
        font-weight: 400;
        align-items: center;
        gap: .25rem;
        text-decoration: none;

        a{
            text-decoration: none;
        }
    }
}